/*******************************************************************************
 * @name ResponsiveModels
 * @author MediaFire <cavitt.glover@mediafire.com>
 * @description Constants and enumerations used for establishing responsive break points.
 ******************************************************************************/

export enum ResponsiveModels {
  Tiny,   // <= 450
  Small,  // 451 - 799
  Medium, // 800 - 1279
  Large   // >= 1280
}

export const WidthBreakPoints = {
  tiny: 451,
  small: 800,
  medium: 1280
};
