/*******************************************************************************
 * @name UploadStore
 * @author MediaFire <cavitt.glover@mediafire.com>
 * Bridges the SDK and the handles the state of the uploader.
 ******************************************************************************/

import * as RX from 'reactxp';
import * as _ from 'lodash';
import {autoSubscribe, AutoSubscribeStore, StoreBase} from 'resub';
import Reporter from 'modules/reporter';
import {UploadFileStates, UploadFile} from './UploaderModels';
import AppConfig from '../app/AppConfig';
import AppDatabase from '../app/AppDatabase';
import Dialog from '../common/Dialog';

const _mfsdk = require('../../assets/sdk/uploader.js');
const _dialogMergeConflict = 'dialog-merge-conflict';
const _q = (n) => {
  var m = RegExp('[?&]'+n+'=([^&]*)').exec(window.location.search);
  return m && decodeURIComponent(m[1].replace(/\+/g, ' '));
};

@AutoSubscribeStore
class UploadStore extends StoreBase {
  private _counter = 0;
  private _sizeLimit = 21474836580;
  private _files: UploadFile[] = [];
  private _transport: any = null;
  private _started: boolean = false;
  private _minimized: boolean = false;
  private _interval: number | null = null;
  private _token: string | null = null;
  private _eta: string = '';
  private _folderkey: string = 'myfiles';
  private _filedrop: boolean = false;
  private _filedropKey: string | null = null;
  private _filedropTitle: string = '';
  private _onUpdate(
    uploader: any,
    file: any,
    state: string,
    quickkey?: string,
    duplicate?: string,
    error?: Error,
  ) {
    let newState: UploadFileStates;
    switch(state) {
      case 'hash-queue': newState = UploadFileStates.Queued; break;
      case 'hashing': newState = UploadFileStates.Hashing; break;
      case 'hashed': newState = UploadFileStates.Hashed; break;
      case 'pre-upload': newState = UploadFileStates.Checking; break;
      case 'upload-queue': newState = UploadFileStates.Waiting; break;
      case 'uploading': newState = UploadFileStates.Uploading; break;
      case 'verifying': newState = UploadFileStates.Verifying; break;
      case 'complete': newState = UploadFileStates.Finished; break;
      case 'duplicate': newState = UploadFileStates.Duplicate; break;
      case 'skipped': newState = UploadFileStates.Skipped; break;
      case 'failed': newState = UploadFileStates.Failed; break;
    }

    const key = file.quickkey || quickkey;
    const path = file.path || null;

    // Finished upload, sync MyFiles
    if (newState === UploadFileStates.Finished) {
      // Legacy sync items
      try {
        parent.postMessage(JSON.stringify({
          event: 'mf-upload-complete',
          folder: this._folderkey,
          id: key,
          path: path
        }), '*');
        parent['_syncUploads'](this._folderkey, key, path);
      } catch(e) {}
    }

    // Update file in files list
    this._files = this._files ? _.map(
      this._files, old => old.ref === file
        ? {...old, state: newState, quickkey: key, duplicate, error}
        : old
    ) : [];
    this.trigger();
  }

  private _onProgress(event:string, uploader: Object, file: File, bytes: number) {
    if (event === 'upload') {
      const eta = uploader['eta'] && uploader['eta']();
      if (eta) this._eta = eta.message;
    }

    this._files = this._files ? _.map(this._files, old => {
      if (old.ref !== file) return old;

      const hashed = bytes || file['bytesHashed'];
      const uploaded = bytes || file['bytesUploaded'];
      const hashedPrevious = old.progress ? old.progress.hashed : 0;
      const uploadedPrevious = old.progress ? old.progress.uploaded : 0;
      const progress = {
        hashed: event === 'hash' ? Math.max(hashed, hashedPrevious) : hashedPrevious,
        uploaded: event === 'upload' ? Math.max(uploaded, uploadedPrevious) : uploadedPrevious,
        speed: 0, // TODO
        remaining: 0, // TODO
      };

      return {...old, progress};
    }) : [];

    this.trigger();
  }

  private _onConflict(uploader: any, file: File) {
    const dialog = (
      <Dialog
        prompt
        dialogId={_dialogMergeConflict}
        title={'Duplicate Files'}
        text={'Files with conflicting names exist in this folder.\nChoose what to do with duplicate files.'}
        buttons={[{
          text: 'SKIP',
          outline: true,
          onPress: () => {
            Dialog.dismissAnimated(_dialogMergeConflict);
            uploader.duplicateAction(file, 'skip', true);
          }
        }, {
          text: 'REPLACE',
          secondary: true,
          onPress: () => {
            Dialog.dismissAnimated(_dialogMergeConflict);
            uploader.duplicateAction(file, 'replace', true);
          }
        }, {
          text: 'KEEP BOTH',
          primary: true,
          onPress: () => {
            Dialog.dismissAnimated(_dialogMergeConflict);
            uploader.duplicateAction(file, 'keep', true);
          }
        }]}
      />
    );

    this.maximize();
    RX.Modal.show(dialog, _dialogMergeConflict);
  }

  startup() {
    return AppDatabase.getAllUploads().then(uploads => {
      this._files = uploads;
    });
  }

  addUploads(files: FileList | File[]) {
    const items = [].slice.call(files);
    items.sort((a, b) => a.size - b.size);

    for (let i = 0; i < items.length; i++) {
      const file = items[i];

      // Filter junk files
      const name = file.name.toLowerCase();
      if (name === '.ds_store' || name === 'thumbs.db' || name === 'desktop.ini') continue;
      if (file.size === 0) continue;

      const upload: UploadFile = {
        id: ++this._counter,
        ref: file,
        name: file.name,
        size: file.size,
        type: file.type,
        state: UploadFileStates.Queued,
        dates: {
          created: new Date(),
          modified: new Date(file.lastModified),
          eta: null
        },
        error: null
      };
  
      this._files = this._files.concat(upload);
      if (this._transport) this._transport.add(file);
      AppDatabase.putUpload(upload);
    }

    this.trigger();
    return this._files;
  }

  fetchFiledropInfo(filedropKey) {
    const xhr = new XMLHttpRequest();
    const self = this;
    xhr.addEventListener('load', function() {
      let data;
      try {data = JSON.parse(this.responseText)} catch(e) {};
      self._filedropTitle = data && data.response && data.response.title
        || 'Upload to my MediaFire FileDrop';
      self.trigger();
    });

    const host = AppConfig.getBackendHost();
    xhr.open('GET', `${host}/application/filedrop_info.php?drop=${filedropKey}`);
    xhr.send();
  }

  fetchSession(callback: Function) {
    const host = AppConfig.getBackendHost();
    const xhr = new XMLHttpRequest();
    xhr.addEventListener('load', function() {
      let data;
      try {data = JSON.parse(this.responseText)} catch(e) {};
      if (!data || !data.response || !data.response.session_token) return;
      let token = data.response.session_token;

      const xhr2 = new XMLHttpRequest();
      xhr2.addEventListener('load', function() {
        let data;
        try {data = JSON.parse(this.responseText)} catch(e) {};
        if (!data || !data.response || !data.response.action_token) return;
        token = data.response.action_token;
        callback(token);
      });

      xhr2.open('GET', host + '/api/'
        + AppConfig.getBackendVersion()
        + '/user/get_action_token.php'
        + '?type=upload&lifespan=1440&response_format=json'
        + '&session_token=' + token);
      xhr2.send();
    });
    xhr.open('GET', host + '/application/get_session_token.php');
    xhr.send();
  }

  addSession() {
    if (_q('drop') && _q('key')) {
      this.fetchFiledropInfo(_q('key'));
      this.initSession(null);
    } else {  
      this.fetchSession(token => this.initSession(token));
    }
  }

  initSession = (token) => {
    // Legacy configuration
    let _session: any;
    let _id: any;
    try {
      const query = location && location.search.substr(1).split('&');
      const params = query && query.length && query.map(e => {
        const items = e.split('=');
        return {[items[0]]: items[1]};
      });
      _id = params && params[0] && parseInt(params[0].id, 10) || 0;
      _session = parent && (parent['__UPLOADER__'] || parent[`__UPLOADER:${_id}__`]);
      this._folderkey = (_session && 'folder' in _session) ? _session.folder : 'myfiles';
      this._token = (_session && 'token' in _session) ? _session.token : token;
      this._sizeLimit = (_session && 'sizeLimit' in _session) ? _session.sizeLimit : this._sizeLimit;
    } catch (e) {}

    // URL-based configuration
    if (_q('drop')) {
      this._filedropKey = _q('key');
      this._filedrop = true;
    } else if (_q('folder')) {
      this._token = this._token || token;
      this._folderkey =  _q('folder') ? _q('folder') : 'myfiles';
      this._sizeLimit = _q('limit') ? parseInt(_q('limit'), 10) : this._sizeLimit;
    }

    // Renew session
    if (this._token) {
      this._interval = setInterval(() => {
        this.fetchSession(token => this._token = token)
      }, 60 * 60 * 1000);
    }

    // SDK configuration
    this._transport = new _mfsdk(this._token, {
      onUpdate: (uploader:Object, file:File, state:string, quickkey:string, duplicate:string, error:Error) =>
        this._onUpdate(uploader, file, state, quickkey, duplicate, error),
      onDuplicateConfirm: (uploader:Object, file:File) =>
        this._onConflict(uploader, file),
      onUploadProgress: (uploader:Object, file:File, bytes:number) =>
        this._onProgress('upload', uploader, file, bytes),
      onHashProgress: (uploader:Object, file:File, bytes:number) =>
        this._onProgress('hash', uploader, file, bytes),
    }, {
      folderkey: this._folderkey,
      filedrop: this._filedropKey,
      resourcePath: 'sdk/',
      apiUrl: AppConfig.getBackendHost() + '/api/',
      apiVersion: AppConfig.getBackendVersion(),
      uploadOnAdd: false,
      returnThumbnails: false,
      disableInstantUploads: false,
      concurrentUploads: 5,
      retryAttempts: 10,
      reporter: Reporter.client,
    });

    if (_session && 'files' in _session && this._transport) {
      this._started = true;
      this.addUploads(_session.files);
      this.startSession();
      parent[`__UPLOADER:${_id}__`] = null;
    } else if (this._started && this._transport) {
      this._transport.startUpload();
    }

    this.trigger();
  }

  startSession() {
    this._started = true;
    if (!this._transport) return;
    this._transport.startUpload();
  }

  resetSession() {
    this._files = [];
    this._started = false;
    if (this._transport) this._transport.cancel();
    this.trigger();
  }

  @autoSubscribe
  getUploads() {
    return this._files;
  }

  @autoSubscribe
  getUploadById(id: number) {
    return _.find(this._files, upload => upload.id === id);
  }

  deleteUpload(id: number) {
    this._files = _.filter(this._files, upload => upload.id !== id);
    this.trigger();
  }

  retryUpload(id: number) {
    const upload = _.find(this._files, upload => upload.id === id);
    this._transport.add(upload.ref, id);
    this.trigger();
  }

  public interval() {
    this._interval;
  }

  public eta() {
    return this._eta;
  }

  @autoSubscribe
  public getFileLimit() {
    return this._sizeLimit;
  }

  @autoSubscribe
  public getFolderKey() {
    return this._folderkey;
  }

  @autoSubscribe
  public getFiledropTitle() {
    return this._filedropTitle;
  }

  @autoSubscribe
  public isStarted() {
    return this._started;
  }

  @autoSubscribe
  public isMinimized() {
    return this._minimized;
  }

  @autoSubscribe
  public isFiledrop() {
    return this._filedrop;
  }

  public minimize() {
    this._minimized = true;
    this.trigger();
    try{
      parent.postMessage('mf-uploader-minimized', '*');
      parent.document.getElementById('uploader_window_popup').className = 'popup minimized';
    }catch(e){}
  }

  public maximize() {
    this._minimized = false;
    this.trigger();
    try{
      parent.postMessage('mf-uploader-maximized', '*');
      parent.document.getElementById('uploader_window_popup').className = 'popup';
    }catch(e){}
  }
}

export default new UploadStore();
