/******************************************************************************
 * @name AppDatabase
 * @author MediaFire <cavitt.glover@mediafire.com>
 * @description Local database implementation and interface for the app.
*******************************************************************************/

import {DbProvider, DbSchema} from 'nosqlprovider';
import * as SyncTasks from 'synctasks';
import * as UploaderModels from '../uploader/UploaderModels';

type DBStore<Name extends string, ObjectType, KeyFormat> = string & {
  name?: Name, objectType?: ObjectType, keyFormat?: KeyFormat
};

type DBIndex<Store extends DBStore<string, any, any>, IndexKeyFormat> = string & {
  store?: Store, indexKeyFormat?: IndexKeyFormat
};

const Stores = {
  UploadItems: 'UploadItems_V1' as DBStore<'UploadItemStore', UploaderModels.UploadFile, string>
};

const Indexes = {
  Search: 'UploadItems_Search_V1' as DBIndex<typeof Stores.UploadItems, string>
};

const _appDatabaseName = 'mediafire';
const _appSchemaVersion = 1;
const _appSchema: DbSchema = {
  version: _appSchemaVersion,
  lastUsableVersion: _appSchemaVersion,
  stores: [{
    primaryKeyPath: 'id',
    name: Stores.UploadItems,
    indexes: [{name: Indexes.Search, keyPath: '_Search', fullText: true}]
  }]
};

class AppDatabase {
  private _db: DbProvider | undefined;

  open(providers: DbProvider[]): SyncTasks.Promise<void> {
    return this._connect(providers, _appDatabaseName, _appSchema).then(prov => {
      this._db = prov;
    });
  }

  private _connect(providers: DbProvider[], dbName: string, schema: DbSchema): SyncTasks.Promise<DbProvider> {
    const task = SyncTasks.Defer<DbProvider>();
    let index = 0;
    let errors: any[] = [];
    const next = () => {
      if (index >= providers.length) {
        task.reject(errors.length <= 1 ? errors[0] : errors);
        return;
      }

      let provider = providers[index];
      provider.open(dbName, schema, false, false).then(() => {
        task.resolve(provider);
      }, err => {
        errors.push(err);
        index++;
        next();
      });
    };

    next();
    return task.promise();
  }

  getAllUploads(): SyncTasks.Promise<UploaderModels.UploadFile[]> {
    if (!this._db) return SyncTasks.Rejected('Database not open');
    return this._db.openTransaction([Stores.UploadItems], false).then(tx => {
      return tx.getStore(Stores.UploadItems);
    }).then(store => {
      return store.openPrimaryKey().getAll() as SyncTasks.Promise<UploaderModels.UploadFile[]>;
    }).fail(this._handleDbFail);
  }

  putUpload(upload: UploaderModels.UploadFile): SyncTasks.Promise<void> {
    if (!this._db) return SyncTasks.Rejected('Database not open');
    return this._db.openTransaction([Stores.UploadItems], true).then(tx => {
      return tx.getStore(Stores.UploadItems);
    }).then(store => {
      return store.put(upload);
    }).fail(this._handleDbFail);
  }

  private _handleDbFail = (err: any) => {
    if (err.target) {
      if (err.target.error) {
        const error = err.target.error;
        console.error(`IDBRequest: ${error.name}: ${error.message}`);
      }
      if (err.target.transaction && err.target.transaction.error) {
        const error = err.target.transaction.error;
        console.error(`IDBTransaction: ${error.name}: ${error.message}`);
      }
      if (err.target.source) {
        const source = err.target.source;
        console.error(`IDBStore: ${source.name}, ${source.keyPath}, indexes: ${source.indexNames.join()}`);
      }
    }
  }
}

export default new AppDatabase();
