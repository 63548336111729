/*******************************************************************************
 * @name AppServices
 * @author MediaFire <cavitt.glover@mediafire.com>
 * @description Registers all services (long-running singleton objects)
 * with the AppServiceManager. Should be called at app launch time.
 ******************************************************************************/

import AppConfig from './AppConfig';
import AppServiceManager from './AppServiceManager';
import NavService from '../nav/NavService';
import UploaderStore from '../uploader/UploaderStore';

export default class AppServices {
  static init() {
    AppServiceManager.registerService(UploaderStore, 'UploaderStore');

    // Web-specific services
    if (AppConfig.getPlatformType() === 'web') {
      AppServiceManager.registerService(NavService, 'NavService');
    }
  }
}
