/*******************************************************************************
 * @name Reporter
 * @author MediaFire <cavitt.glover@mediafire.com>
 * @description Web implementation of "reporter" module.
 ******************************************************************************/

import bugsnag from 'bugsnag-js';
import AppConfig from '../../views/app/AppConfig';
import {ReporterBase} from './Reporter';

class Reporter implements ReporterBase {
  client = bugsnag({
    apiKey: AppConfig.getBugsnagKey(),
    appVersion: AppConfig.getVersion(),
    releaseStage: AppConfig.getStage()
  });
}

export default new Reporter();
