/******************************************************************************
 * @name Text
 * @author MediaFire <cavitt.glover@mediafire.com>
 * @description A simple text component.
*******************************************************************************/

import * as RX from 'reactxp';
import {Colors, Fonts, FontSizes} from '../app/AppStyles';

export interface TextProps {
  onPress?: (e: RX.Types.SyntheticEvent) => void;
  style?: RX.Types.StyleRuleSetRecursive<RX.Types.TextStyle>;
  lines?: number;
  text: string;
}

const _styles = {
  text: RX.Styles.createTextStyle({
    font: Fonts.displayRegular,
    fontSize: FontSizes.size12,
    color: Colors.buttonGreyText,
    lineHeight: 32,
  }),
};

export default class Button extends RX.Component<TextProps, RX.Stateless> {
  render(): JSX.Element | null {
    return (
      <RX.Text
        style={[_styles.text, this.props.style]}
        numberOfLines={this.props.lines || 1}
        onPress={this.props.onPress}
      >
        {this.props.text}
      </RX.Text>
    );
  }
}
