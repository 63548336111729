/******************************************************************************
 * @name AppStyles
 * @author MediaFire <cavitt.glover@mediafire.com>
 * @description Shared style information used throughout the application
*******************************************************************************/

import * as RX from 'reactxp';
import {default as FontRefs} from 'modules/fonts';

export const enum Colors {
  black = '#000000',
  blue = '#07f',
  orange = '#FF8637',
  redOrange = '#EF5939',
  red = '#E23B2A',
  redHover = '#e23b2a',
  redDisabled = '#F9D8D4',
  green = '#66CC33',
  greenHover = '#56ad2a',
  white = '#fff',
  greyOutline = '#e8e9ec',
  greyOutlineHover = '#d2d2d2',
  greyTitle = '#888d9c',

  gray66 = '#666',
  grayEE = '#eee',
  grayF8 = '#f8f8f8',
  transparent = 'rgba(0, 0, 0, 0)',

  headerBackground = 'white',
  contentBackground = 'white',
  lightBackground = '#f4f4f5',

  uploaderBackground = '#282F3D',
  uploaderBarBackground = '#414c63',
  uploaderBarHash = '#56637f',
  uploaderBarProgress = '#07f',
  uploaderMinimizedText = '#e4e4e4',

  logoColor = '#339933',

  borderSeparator = '#e8e9ec',
  borderSeparatorLight = '#f4f4f5',
  separator = '#eee',
  menuText = '#282f3d',
  menuTextSelected = '#225577',
  menuTextDisabled = '#bbb',
  menuTextHover = '#000',
  menuItemHover = '#eee',
  menuBackground = '#fff',
  menuBorder = '#bbb',
  listItemHover = '#e0eeff',
  listItemSelected = '#ddeeff',
  buttonTextColor = '#666',
  buttonLightTextColor = '#999',
  buttonTextHover = '#000',
  buttonGreyText = '#828282',
  buttonGreyBackground = '#e6e6e6',
  buttonGreyBackgroundHover = '#d6d6d6',
  buttonBlueText = '#fff',
  buttonBlueBackground = '#07f',
  buttonBlueBackgroundHover = '#0065d9',
  dialogBackground = '#fff',
  dialogBorder = '#ddd',
  dialogText = '#666',
  dialogBehind = 'rgba(0, 0, 0, 0.43)',
}

export const enum FontSizes {
  size10 = 10,
  size12 = 12,
  size14 = 14,
  size16 = 16,
  size20 = 20,
  size32 = 32,

  menuItem = 16
}

// Font infos
export class Fonts {
  static monospace: RX.Types.FontInfo = {
    fontFamily: FontRefs.monospace
  };

  static displayLight: RX.Types.FontInfo = {
    fontFamily: FontRefs.displayLight
  };

  static displayRegular: RX.Types.FontInfo = {
    fontFamily: FontRefs.displayRegular,
    fontWeight: '400'
  };

  static displaySemibold: RX.Types.FontInfo = {
    fontFamily: FontRefs.displaySemibold,
    fontWeight: '600'
  };

  static displayBold: RX.Types.FontInfo = {
    fontFamily: FontRefs.displayBold,
    fontWeight: '700'
  };
}

// Styles
export class AppStyles {
  static statusBarTopMargin = RX.Styles.createViewStyle({
    marginTop: RX.StatusBar.isOverlay() ? 20 : 0
  });
}

