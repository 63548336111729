/*******************************************************************************
 * @name AppMain
 * @author MediaFire <cavitt.glover@mediafire.com>
 * @description Main entry point for the app, common to both native and web.
 ******************************************************************************/

import Reporter from 'modules/reporter';
import {DbProvider} from 'nosqlprovider';
import * as RX from 'reactxp';
import * as SyncTasks from 'synctasks';
import createPlugin from 'bugsnag-react';
import AppConfig from './AppConfig';
import AppDatabase from './AppDatabase';
import AppRootView from './AppRootView';
import AppServiceManager, {Service} from './AppServiceManager';
import AppServices from './AppServices';
import NavLinkConverter from '../nav/NavLinkConverter';
import NavStore from '../nav/NavStore';
import NavService from '../nav/NavService';
import ResponsiveStore from '../responsive/ResponsiveStore';
import UploaderStore from '../uploader/UploaderStore';

export default abstract class AppMain {
  constructor() {
    // BugSnag error boundary to catch unhandled exceptions
    const ErrorBoundary = Reporter.client.use(createPlugin(RX));

    // Init application
    RX.App.initialize(__DEV__, __DEV__);
    AppServices.init();

    // Open the DB and startup any critical services before displaying the UI.
    AppDatabase.open(this._getDbProvidersToTry()).then(() => {
      return this._startCriticalServices();
    }).then(() => {
      RX.UserInterface.setMainView(this._renderRootView(ErrorBoundary));
      RX.UserInterface.useCustomScrollbars(true);

      // Convert the initial URL into a navigation context.
      this._getInitialUrl().then(url => {
        if (url) {
          let context = NavLinkConverter.getContextFromUrl(url, NavStore.isUsingStackNav());
          if (context) {
            NavStore.setNavContext(context);
          }
        }
      });
    });
  }

  private _startCriticalServices(): SyncTasks.Promise<void> {
    const services: Service[] = [UploaderStore];
    if (AppConfig.getPlatformType() === 'web')
      services.push(NavService);
    return AppServiceManager.ensureStarted(services);
  }

  private _renderRootView(ErrorBoundary) {
    return ErrorBoundary
      ? <ErrorBoundary>
          <AppRootView onLayout={this._onLayoutUpdate} />
        </ErrorBoundary>
      : <AppRootView onLayout={this._onLayoutUpdate} />;
  }

  private _onLayoutUpdate = (e: RX.Types.ViewOnLayoutEvent) => {
    const {width, height} = e;
    // TODO: communicate new height/width to parent frame
    ResponsiveStore.putWindowSize(width, height);
  }

  // Subclasses must override.
  protected abstract _getDbProvidersToTry(): DbProvider[];
  protected abstract _getInitialUrl(): SyncTasks.Promise<string | undefined>;
}
