/*******************************************************************************
 * @name AppHeader
 * @author MediaFire <cavitt.glover@mediafire.com>
 * @description Horizontal bar that appears on the top of every view
 * within the app when it's using stack-based layout.
 ******************************************************************************/

import * as RX from 'reactxp';
import {ComponentBase} from 'resub';
import ImageSource from 'modules/images';
import {Colors, Fonts, FontSizes, AppStyles} from './AppStyles';
import AppConfig from './AppConfig';
import HoverButton from '../common/HoverButton';

const _styles = {
  background: RX.Styles.createViewStyle({
    alignSelf: 'stretch',
    height: 55,
    borderBottomWidth: 1,
    borderColor: Colors.borderSeparator,
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: Colors.lightBackground
  }),
  leftContainer: RX.Styles.createViewStyle({
    flexDirection: 'row',
    alignItems: 'center',
    width: 16
  }),
  rightContainer: RX.Styles.createViewStyle({
    flexDirection: 'row',
    alignItems: 'center',
    width: 46
  }),
  titleContainer: RX.Styles.createViewStyle({
    flex: 1,
    alignSelf: 'stretch',
    justifyContent: 'center'
  }),
  titleText: RX.Styles.createTextStyle({
    flex: -1,
    font: Fonts.displayRegular,
    fontSize: FontSizes.size16,
    color: Colors.menuText,
    textAlign: 'left'
  }),
  buildText: RX.Styles.createTextStyle({
    font: Fonts.displayRegular,
    fontSize: FontSizes.size10,
    lineHeight: 54,
    color: Colors.buttonLightTextColor,
    marginHorizontal: 20,
  }),
  buttonText: RX.Styles.createTextStyle({
    font: Fonts.displayRegular,
    fontSize: FontSizes.size16,
    color: Colors.menuText,
    margin: 8
  }),
  buttonTextHover: RX.Styles.createTextStyle({
    color: Colors.menuTextHover
  }),
  buttonImage: RX.Styles.createImageStyle({
    flex: 1,
    alignSelf: 'center',
    height: 24,
    width: 24,
    opacity: 0.5
  })
};

export interface AppHeaderProps extends RX.CommonProps {
  title: string;
  showBuild: boolean;
  canMinimize: boolean;
  canClose: boolean;
  onClose?: () => void;
  onMinimize?: () => void;
}

export default class AppHeader extends ComponentBase<any/*AppHeaderProps*/, RX.Stateless> {
  render(): JSX.Element | null {
    let leftContents: JSX.Element | undefined;
    let rightContents: JSX.Element | undefined;
    const onPress = this.props.canClose ? this._onPressClose : this._onPressMinimize;
    if (this.props.canMinimize || this.props.canClose) {
      rightContents = (
        <HoverButton onPress={onPress} onRenderChild={this._renderButton}/>
      );
    }

    return (
      <RX.View style={[_styles.background, AppStyles.statusBarTopMargin]}>
        <RX.View style={_styles.leftContainer}>
          {leftContents}
        </RX.View>
        <RX.View style={_styles.titleContainer}>
          <RX.Text style={_styles.titleText} numberOfLines={1}>
            {this.props.title}
          </RX.Text>
        </RX.View>
        {this.props.showBuild &&
          <RX.Text style={_styles.buildText}>
            v{AppConfig.getVersion()}
          </RX.Text>
        }
        <RX.View style={_styles.rightContainer}>
          {rightContents}
        </RX.View>
      </RX.View>
    );
  }

  private _onPressMinimize = (e: RX.Types.SyntheticEvent) => {
    e.stopPropagation();
    if (this.props.onClose) this.props.onMinimize();
  }

  private _onPressClose = (e: RX.Types.SyntheticEvent) => {
    e.stopPropagation();
    if (this.props.onClose) this.props.onClose();
  }

  private _renderButton = (isHovering: boolean) => {
    return (
      <RX.Text style={[_styles.buttonText, isHovering ? _styles.buttonTextHover : undefined]}>
        <RX.Image source={this.props.canClose ? ImageSource.close : ImageSource.minimize} style={_styles.buttonImage} />
      </RX.Text>
    );
  }
}
