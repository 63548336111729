/*******************************************************************************
 * @name ResponsiveStore
 * @author MediaFire <cavitt.glover@mediafire.com>
 * @description Singleton store to hold the responsive width property for the app.
 ******************************************************************************/

import * as RX from 'reactxp';
import {AutoSubscribeStore, autoSubscribeWithKey, disableWarnings, StoreBase} from 'resub';

import {ResponsiveModels, WidthBreakPoints} from './ResponsiveModels';

export enum TriggerKeys {
  ResponsiveModels,
  Width,
  Height
}

const MainWindowId = 'MainWindowId';

@AutoSubscribeStore
class ResponsiveStore extends StoreBase {
  private _rawWidth: { [index: string]: number } = { [MainWindowId]: 0 };
  private _rawHeight: { [index: string]: number } = { [MainWindowId]: 0 };
  private _responsiveWidth: { [index: string]: ResponsiveModels } = { [MainWindowId]: ResponsiveModels.Medium };

  constructor() {
    super();

    // Conditionally seed the window size when it isn't already set.
    if (!this._rawWidth[MainWindowId] || !this._rawHeight[MainWindowId]) {
      const { width, height } = RX.UserInterface.measureWindow();
      this.putWindowSize(width, height);
    }
  }

  static responsiveWidthForWidth(width: number): ResponsiveModels {
    if (width < WidthBreakPoints.tiny) {
      return ResponsiveModels.Tiny;
    } else if (width >= WidthBreakPoints.tiny && width < WidthBreakPoints.small) {
      return ResponsiveModels.Small;
    } else if (width >= WidthBreakPoints.small && width < WidthBreakPoints.medium) {
      return ResponsiveModels.Medium;
    } else {
      return ResponsiveModels.Large;
    }
  }

  @disableWarnings
  putWindowSize(width: number, height: number, rootViewId: string = MainWindowId) {
    const triggers: TriggerKeys[] = [];

    // No need to re-calc when the width is unchanged
    let widthUpdated = this._rawWidth[rootViewId] !== width;
    if (widthUpdated) {
      this._rawWidth[rootViewId] = width;
      triggers.push(TriggerKeys.Width);

      const responsiveWidth = ResponsiveStore.responsiveWidthForWidth(width);
      if (this._responsiveWidth[rootViewId] !== responsiveWidth) {
        this._responsiveWidth[rootViewId] = responsiveWidth;
        triggers.push(TriggerKeys.ResponsiveModels);
      }
    }

    // No need to re-calc when the height is unchanged
    if (this._rawHeight[rootViewId] !== height) {
      this._rawHeight[rootViewId] = height;
      triggers.push(TriggerKeys.Height);
    }

    // Trigger all changes at once.
    this.trigger(triggers);
  }

  @autoSubscribeWithKey(TriggerKeys.Width)
  getWidth(rootViewId: string = MainWindowId): number {
    return this._rawWidth[rootViewId];
  }

  @disableWarnings
  getWidthNoSubscription(rootViewId: string = MainWindowId): number  {
    return this._rawWidth[rootViewId];
  }

  @autoSubscribeWithKey(TriggerKeys.Height)
  getHeight(rootViewId: string = MainWindowId): number  {
    return this._rawHeight[rootViewId];
  }

  @disableWarnings
  getHeightNoSubscription(rootViewId: string = MainWindowId): number {
    return this._rawHeight[rootViewId];
  }

  @autoSubscribeWithKey(TriggerKeys.ResponsiveModels)
  getResponsiveModels(rootViewId: string = MainWindowId): number {
    return this._responsiveWidth[rootViewId];
  }

  @autoSubscribeWithKey(TriggerKeys.ResponsiveModels)
  isSmallOrTinyScreenSize(rootViewId: string = MainWindowId): boolean {
    return this._responsiveWidth[rootViewId] <= ResponsiveModels.Small;
  }

  @autoSubscribeWithKey(TriggerKeys.ResponsiveModels)
  isTinyWidth(rootViewId: string = MainWindowId): boolean {
    return this._responsiveWidth[rootViewId] <= ResponsiveModels.Tiny;
  }

  @disableWarnings
  isHeightSmallerThanThresholdNoSubscription(threshold: number, rootViewId: string = MainWindowId): boolean {
    const size = this.getWindowDimensionsNoSubscription(rootViewId);
    return size.height <= threshold;
  }

  @disableWarnings
  isWidthSmallerThanThresholdNoSubscription(threshold: number, rootViewId: string = MainWindowId): boolean {
    const size = this.getWindowDimensionsNoSubscription(rootViewId);
    return size.width <= threshold;
  }

  @disableWarnings
  getWindowDimensionsNoSubscription(rootViewId: string = MainWindowId): RX.Types.Dimensions {
    return RX.UserInterface.measureWindow(rootViewId === MainWindowId ? undefined : rootViewId);
  }
}

export default new ResponsiveStore();
