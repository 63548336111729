/******************************************************************************
 * @name Button
 * @author MediaFire <cavitt.glover@mediafire.com>
 * @description A "classic" button with text as a label.
*******************************************************************************/

import * as RX from 'reactxp';
import HoverButton from './HoverButton';
import {Colors, Fonts, FontSizes} from '../app/AppStyles';

export interface ButtonProps {
  onPress?: (e: RX.Types.SyntheticEvent) => void;
  title?: string;
  value: string;
  text?: boolean;
  primary?: boolean;
  secondary?: boolean;
  warn?: boolean;
  outline?: boolean;
  disabled?: boolean;
  textStyle?: RX.Types.StyleRuleSetRecursive<RX.Types.TextStyle>;
  buttonStyle?: RX.Types.StyleRuleSetRecursive<RX.Types.ViewStyle>;
  buttonHoverStyle?: RX.Types.StyleRuleSetRecursive<RX.Types.ViewStyle>;
  textHoverStyle?: RX.Types.StyleRuleSetRecursive<RX.Types.TextStyle>;
}

const _styles = {
  button: RX.Styles.createViewStyle({
    borderWidth: 0,
  }),
  text: RX.Styles.createTextStyle({
    font: Fonts.displayRegular,
    fontSize: FontSizes.size12,
    color: Colors.buttonGreyText,
    lineHeight: 40,
    textAlign: 'center',
  }),
  textPrimary: RX.Styles.createTextStyle({
    color: Colors.blue,
  }),
  textSecondary: RX.Styles.createTextStyle({
    color: Colors.green,
  }),
  textWarning: RX.Styles.createTextStyle({
    color: Colors.orange,
  }),
  textWhite: RX.Styles.createTextStyle({
    color: Colors.white,
  }),
  textOutline: RX.Styles.createTextStyle({
    color: Colors.greyOutline,
  }),
  textHover: RX.Styles.createTextStyle({
    color: Colors.orange,
  }),
  background: RX.Styles.createViewStyle({
    backgroundColor: Colors.buttonGreyBackground,
    borderRadius: 3,
    paddingVertical: 0,
    paddingHorizontal: 16
  }),
  backgroundPrimary: RX.Styles.createViewStyle({
    backgroundColor: Colors.buttonBlueBackground,
  }),
  backgroundWarning: RX.Styles.createViewStyle({
    backgroundColor: Colors.red,
  }),
  backgroundSecondary: RX.Styles.createViewStyle({
    backgroundColor: Colors.green,
  }),
  backgroundOutline: RX.Styles.createViewStyle({
    backgroundColor: Colors.white,
    borderColor: Colors.greyOutline,
    borderWidth: 2,
  }),
  backgroundHover: RX.Styles.createViewStyle({
    backgroundColor: Colors.buttonGreyBackgroundHover
  }),
  buttonPrimaryHoverBackground: RX.Styles.createViewStyle({
    backgroundColor: Colors.buttonBlueBackgroundHover
  }),
  buttonSecondaryHoverBackground: RX.Styles.createViewStyle({
    backgroundColor: Colors.greenHover
  }),
  buttonOutlineHoverBackground: RX.Styles.createViewStyle({
    backgroundColor: Colors.greyOutlineHover
  }),
  buttonWarningHoverBackground: RX.Styles.createViewStyle({
    backgroundColor: Colors.redHover
  }),
};

export default class Button extends RX.Component<ButtonProps, RX.Stateless> {
  render(): JSX.Element | null {
    return (
      <HoverButton
        title={this.props.title}
        disabled={this.props.disabled}
        onPress={this.props.onPress}
        onRenderChild={this._onRenderButton}
      />
    );
  }

  private _onRenderButton = (hovering: boolean) => {
    const {primary, secondary, warn, text, outline} = this.props;
    let buttonStyles = [_styles.button, this.props.buttonStyle];
    let textStyles = [_styles.text, this.props.textStyle];

    // Text button
    if (text) {
      if (primary) textStyles.push(_styles.textPrimary);
      else if (secondary) textStyles.push(_styles.textSecondary);
      else if (warn) textStyles.push(_styles.textWarning);

    // Normal button (w/ background)
    } else {
      buttonStyles.push(_styles.background);
      if (primary) {
        textStyles.push(_styles.textWhite);
        buttonStyles.push(_styles.backgroundPrimary);
      } else if (outline) {
        buttonStyles.push(_styles.backgroundOutline);
      } else if (secondary) {
        textStyles.push(_styles.textWhite);
        buttonStyles.push(_styles.backgroundSecondary);
      } else if (warn) {
        textStyles.push(_styles.textWhite);
        buttonStyles.push(_styles.backgroundWarning);
      }
    }

    // Hover text button
    if (text && hovering) {
      textStyles.push(_styles.textHover);

    // Hover normal button
    } else if (hovering) {
      if (primary) buttonStyles.push(_styles.buttonPrimaryHoverBackground);
      else if (secondary) buttonStyles.push(_styles.buttonSecondaryHoverBackground);
      else if (warn) buttonStyles.push(_styles.buttonWarningHoverBackground);
      else if (outline) buttonStyles.push(_styles.buttonOutlineHoverBackground);
      else buttonStyles.push(_styles.backgroundHover);
    }

    // Render
    return (
      <RX.View style={buttonStyles}>
        <RX.Text style={textStyles}>{this.props.value}</RX.Text>
      </RX.View>
    );
  }
}
