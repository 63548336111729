/**
* index.web.ts
*
* Web implementation of "images" module.
*/

import AppConfig from '../../views/app/AppConfig';
import {ImageSourceBase} from './Images';

class ImageSource implements ImageSourceBase {
  logo = AppConfig.getImagePath('logo.png');
  logoSmall = AppConfig.getImagePath('logo-small.png');
  add = AppConfig.getImagePath('add.svg');
  close = AppConfig.getImagePath('close.svg');
  minimize = AppConfig.getImagePath('minimize.svg');
  fileNew = AppConfig.getImagePath('file-new.svg');
  folderNew = AppConfig.getImagePath('folder-new.svg');
  trash = AppConfig.getImagePath('trash.svg');
  dots = AppConfig.getImagePath('dots.svg');
}

export default new ImageSource();
