/*******************************************************************************
 * @name UploaderItem
 * @author MediaFire <cavitt.glover@mediafire.com>
 * @description Renders a list item that represents an "uploader item" view.
 ******************************************************************************/

import * as RX from 'reactxp';
import {ComponentBase} from 'resub';
import ImageSource from 'modules/images';
import {UploadFileStates/*, UploadFile*/} from './UploaderModels';
import UploaderStore from './UploaderStore';
import Dialog from '../common/Dialog';
import Text from '../common/Text';
import Button from '../common/Button';
import HoverButton from '../common/HoverButton';
import {Colors, Fonts, FontSizes} from '../app/AppStyles';
import NavStore from '../nav/NavStore';
import {bytesize} from '../../utilities/Format';
import {shareLink, copyLink} from '../../utilities/Content';
import {isBrowserMobile} from '../../utilities/Browser';

interface UploaderItemState {
  showErrorDetails: boolean;
}
/*interface UploaderItemProps extends RX.CommonProps {
  upload: UploadFile;
  isUploading: boolean;
  isSelected: boolean;
  query?: string;
  onPress: (upload: UploadFile) => void;
}*/

const _itemBorderWidth = 1;
const _dialogRemoveConfirm = 'dialog-remove-confirm';

const _styles = {
  container: RX.Styles.createButtonStyle({
    height: 46,
    backgroundColor: Colors.white,
    borderBottomWidth: _itemBorderWidth,
    borderColor: Colors.borderSeparatorLight,
    alignSelf: 'stretch',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  }),
  name: RX.Styles.createTextStyle({
    font: Fonts.displayRegular,
    fontSize: FontSizes.size14,
    color: Colors.menuText,
    flex: 2,
    marginLeft: 16,
    marginRight: 16,
    width: 300,
  }),
  statusWrapper: RX.Styles.createViewStyle({
    flex: -1,
    width: 100,
    position: 'relative',
    overflow: 'visible',
    cursor: 'pointer',
  }),
  status: RX.Styles.createTextStyle({
    width: 100,
    textAlign: 'center',
    fontSize: FontSizes.size12,
    color: Colors.gray66,
  }),
  statusTooltip: RX.Styles.createTextStyle({
    textAlign: 'center',
    fontSize: FontSizes.size12,
    color: Colors.white,
    backgroundColor: Colors.black,
    paddingHorizontal: 10,
    paddingVertical: 2,
    position: 'absolute',
    left: 105,
    top: -2,
    ['zIndex' as any]: 10000
  }),
  size: RX.Styles.createTextStyle({
    font: Fonts.displayRegular,
    fontSize: FontSizes.size12,
    color: Colors.menuText,
    flex: -1,
    marginLeft: 16,
    width: 90,
    textAlign: 'right',
  }),
  column: RX.Styles.createViewStyle({
    flex: -1,
    marginLeft: 16,
    width: 90,
  }),
  selected: RX.Styles.createButtonStyle({
    backgroundColor: Colors.listItemSelected
  }),
  bar: RX.Styles.createViewStyle({
    height: 4,
    width: 100,
    maxWidth: 100,
    marginRight: 16,
    marginLeft: 16,
    backgroundColor: Colors.lightBackground,
    position: 'relative',
  }),
  share: RX.Styles.createViewStyle({
    flexGrow: 1,
    flexShrink: 0,
    flexDirection: 'row',
    overflow: 'hidden',
    justifyContent: 'flex-end',
  }),
  shareText: RX.Styles.createTextStyle({
    fontSize: FontSizes.size12,
    color: Colors.blue,
  }),
  copyText: RX.Styles.createTextStyle({
    marginLeft: 16,
    color: Colors.blue,
    fontSize: FontSizes.size12,
  }),
  statusText: RX.Styles.createTextStyle({
    color: Colors.menuText,
    textAlign: 'right',
  }),
  retryText: RX.Styles.createTextStyle({
    textAlign: 'right',
  }),
  cancelImage: RX.Styles.createImageStyle({
    height: 16,
    width: 17,
    marginLeft: 32,
    marginRight: 32,
    opacity: 0.7,
    alignSelf: 'flex-end',
  }),
};

export default class UploadManagementView extends ComponentBase<any/*UploaderItemProps*/, UploaderItemState> {
  protected _buildState(props: any, initState: boolean): Partial<UploaderItemState> | undefined {
    let state: Partial<UploaderItemState> = {};
    state.showErrorDetails = false;
    return state;
  }

  render(): JSX.Element | null {
    const {name, size, state, progress, quickkey, duplicate, error} = this.props.upload;

    // Dynamic styles
    let styles = [_styles.container];
    if (this.props.isSelected) styles.push(_styles.selected);

    // Active (upload & hash progress)
    let uploaded = progress ? progress.uploaded : 0;
    let hashed = progress ? progress.hashed : 0;

    // When to consider completed progress
    if (uploaded > 0) hashed = size;
    if (state === UploadFileStates.Finished) uploaded = size;

    // Data
    const started = uploaded > 0 || hashed > 0;
    const bytes = started ? uploaded > 0 ? uploaded : hashed : 0;
    const percent = started ? Math.max(0, Math.min(100, (bytes / size) * 100)) : 0;
    const itemkey = quickkey || duplicate;

    // States
    const isStarted = this.props.isUploading;
    const isSkipped = state === UploadFileStates.Skipped;
    const isFailed = state === UploadFileStates.Failed;
    const isUploading = state == UploadFileStates.Uploading || uploaded > 0 && state == UploadFileStates.Waiting;
    const isShareable = !!itemkey && !isSkipped && !isFailed && state >= UploadFileStates.Waiting;
    const isActive = !isShareable && !isFailed && !isSkipped && state >= UploadFileStates.Checking;
    const isStopped = isFailed && !isShareable && !isActive;
    const isPreparing = !isShareable && !isFailed && !isSkipped && !isActive && isStarted;
    const isGenerating = !isShareable && isActive && !isSkipped;
    const isMobile = isBrowserMobile();

    let errorText = '';
    if (isStopped) {
      if (error instanceof Error) {
        errorText = error.message || error.name;
      } else if (typeof error === 'string') {
        errorText = error;
      } else {
        errorText = 'Unknown error';
      }
    }

    // Render
    return (
      <RX.View style={styles}>
        <Text style={_styles.name} text={name}/>
        {isSkipped && <Text style={_styles.status} text={'Upload skipped'}/>}
        {isStopped &&
          <RX.View style={_styles.statusWrapper}
            onMouseEnter={this._onHoverErrorEnter}
            onMouseLeave={this._onHoverErrorLeave}>
            <Text style={_styles.status} text={'Failed to upload'}/>
            {this.state.showErrorDetails && <Text style={_styles.statusTooltip} text={errorText}/>}
          </RX.View>
        }
        {isPreparing && <Text style={_styles.status} text={'In progress…'}/>}
        {isGenerating && <Text style={_styles.status} text={'Generating link…'}/>}
        {isShareable && this._renderShareActions()}
        {!isMobile && <Text style={_styles.size} text={bytesize(size)}/>}
        {!isMobile &&
          <RX.View style={_styles.column}>
            {this._renderStatus(isUploading, state, percent)}
          </RX.View>
        }
        {isStarted
          ? this._renderProgressBar(hashed, uploaded, size)
          : <HoverButton onPress={this._onPressDelete} onRenderChild={this._renderCancelImage}/>
        }
      </RX.View>
    );
  }

  private _renderStatus = (isUploading, state, percent) => {
    // Retry button
    if (state === UploadFileStates.Failed) {
      return <Button text primary value="Retry?" textStyle={_styles.retryText} onPress={this._onPressRetry}/>;
    }

    // Upload progress or state name
    const statusText = isUploading ? `${percent.toFixed(2)}%` : this._getStateName(state);
    return <Text style={_styles.statusText} text={statusText}/>;
  }

  private _renderProgressBar = (hashed: number, uploaded: number, size: number) => {
    return (
      <RX.View style={_styles.bar}>
        <div style={{width: (hashed / size) * 100, backgroundColor: Colors.buttonGreyBackgroundHover, maxWidth: 100, height: 4, position: 'absolute'}} />
        <div style={{width: (uploaded / size) * 100, backgroundColor: Colors.buttonBlueBackground, maxWidth: 100, height: 4, position: 'absolute'}} />
      </RX.View>
    );
  }

  private _renderShareActions = () => {
    return (
      <RX.View style={_styles.share}>
        <HoverButton onPress={this._onPressShare} onRenderChild={this._renderShareText}/>
        <HoverButton onPress={this._onPressCopy} onRenderChild={this._renderCopyText}/>
      </RX.View>
    );
  }

  private _renderShareText = () => {
    return <Text style={_styles.shareText} text={'Share'}/>;
  }

  private _renderCopyText = () => {
    const isMobile = isBrowserMobile();
    return <Text style={_styles.copyText} text={isMobile ? 'Copy' : 'Copy Link'}/>;
  }

  private _renderCancelImage = () => {
    return <RX.Image source={ImageSource.trash} style={_styles.cancelImage}/>;
  }

  private _onPressDelete = (e: RX.Types.SyntheticEvent) => {
    e && e.stopPropagation();
    const dialog = (
      <Dialog
        dialogId={_dialogRemoveConfirm}
        title={'Remove file upload'}
        text={'Are you sure you want to remove this file from the queue?'}
        buttons={[{
          text: 'CANCEL',
          outline: true,
          isCancel: true,
          onPress: () => {
            Dialog.dismissAnimated(_dialogRemoveConfirm);
          }
        }, {
          text: 'REMOVE',
          warn: true,
          isSubmit: true,
          onPress: () => {
            Dialog.dismissAnimated(_dialogRemoveConfirm);
            this._onConfirmDelete();
          }
        }]}
      />
    );

    RX.Modal.show(dialog, _dialogRemoveConfirm);
  }

  private _onHoverErrorEnter = (e: RX.Types.SyntheticEvent) => {
    e && e.stopPropagation();
    this.setState({showErrorDetails: true});
  }

  private _onHoverErrorLeave = (e: RX.Types.SyntheticEvent) => {
    e && e.stopPropagation();
    this.setState({showErrorDetails: false});
  }

  private _onPressShare = (e: RX.Types.SyntheticEvent) => {
    e && e.stopPropagation();
    const {quickkey, state} = this.props.upload;
    shareLink(quickkey, state);
  }

  private _onPressCopy = (e?: RX.Types.SyntheticEvent) => {
    e && e.stopPropagation();
    const {quickkey, name, type} = this.props.upload;
    copyLink(quickkey, name, type);
  }

  private _onPressRetry = (e?: RX.Types.SyntheticEvent) => {
    e && e.stopPropagation();
    UploaderStore.retryUpload(this.props.upload.id);
  }

  private _onConfirmDelete() {
    UploaderStore.deleteUpload(this.props.upload.id);
    NavStore.navigateToUploader();
  }

  private _getStateName = (state: UploadFileStates) => {
    switch (state) {
      case 0: return 'Queued';
      case 1: return 'Analyzing';
      case 2: return 'Queued';
      case 3: return 'Checking';
      case 4: return 'Duplicate';
      case 5: return 'Queued';
      case 6: return 'Uploading';
      case 7: return 'Verifying';
      case 8: return 'Complete';
      case 9: return 'Failed';
      case 10: return 'Skipped';
      default: return 'Unknown';
    }
  }
}
