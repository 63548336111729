/*
* NavActions.tsx
*
* Constructs navigation contexts.
*/

import * as NavModels from './NavModels';

export default class NavActions {
  static createUploaderContext(useStackNav: boolean, selectedUploadId?: string, showNewUploaderPanel = false) {
    if (useStackNav) {
      let navContext = new NavModels.StackRootNavContext();
      navContext.stack.push(new NavModels.UploaderViewNavContext(selectedUploadId));
      if (showNewUploaderPanel) {
        navContext.stack.push(new NavModels.NewUploadViewNavContext());
      } else if (selectedUploadId !== undefined) {
        navContext.stack.push(new NavModels.ViewUploadViewNavContext(selectedUploadId));
      }
      return navContext;
    } else {
      return new NavModels.UploadRootNavContext(selectedUploadId, showNewUploaderPanel);
    }
  }
}
