/*******************************************************************************
 * @name UploaderPanel
 * @author MediaFire <cavitt.glover@mediafire.com>
 * @description Displays the first screen of the "uploader" view.
 ******************************************************************************/

import * as RX from 'reactxp';
import {VirtualListView, VirtualListViewItemInfo} from 'reactxp-virtuallistview';
import {ComponentBase} from 'resub';
import ImageSource from 'modules/images';
import UploaderStore from './UploaderStore';
import UploaderItem from './UploaderItem';
import {UploadFileStates, UploadFile} from './UploaderModels';
import Button from '../common/Button';
import Text from '../common/Text';
import {Colors, Fonts, FontSizes} from '../app/AppStyles';
import AppTopBarStack from '../app/AppHeader';
import {bytesize} from '../../utilities/Format';
import {copyLink} from '../../utilities/Content';

interface UploaderItemInfo extends VirtualListViewItemInfo {
  upload: UploadFile;
}

export interface UploaderPanelProps extends RX.CommonProps {
  selectedUploadId?: number;
  onSelect: (selectedId: string) => void;
  onCreateNew: () => void;
}

interface UploaderPanelState {
  session: Object;
  minimized: boolean;
  started: boolean;
  uploads: UploaderItemInfo[];
  view: UploaderItemInfo[];
  destination: string;
  sizeLimit: number;
  query: string;
  filedrop: boolean;
  filedropTitle: string;
}

const _styles = {
  list: RX.Styles.createViewStyle({
    flexDirection: 'column',
    flexGrow: 1,
    alignSelf: 'stretch',
    backgroundColor: Colors.contentBackground
  }),
  listNone: RX.Styles.createViewStyle({
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    backgroundColor: Colors.contentBackground
  }),
  uploaderHeader: RX.Styles.createViewStyle({
    height: 67,
    borderTopWidth: 1,
    borderColor: Colors.borderSeparator,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Colors.lightBackground
  }),
  uploaderMinimized: RX.Styles.createViewStyle({
    height: 67,
    width: 350,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Colors.uploaderBackground
  }),
  searchBox: RX.Styles.createTextInputStyle({
    font: Fonts.displayRegular,
    fontSize: FontSizes.size14,
    borderWidth: 1,
    borderColor: Colors.borderSeparator,
    flex: 1,
    padding: 4,
    marginLeft: 12
  }),
  container: RX.Styles.createViewStyle({
    flex: 1,
    alignSelf: 'stretch',
    backgroundColor: Colors.contentBackground
  }),
  addUploadImageContainerFiledrop: RX.Styles.createViewStyle({
    height: 170,
    width: 170,
    borderWidth: 3,
    borderRadius: 5,
    borderStyle: 'dashed',
    borderColor: Colors.black,
    opacity: 0.07,
  }),
  addUploadImageContainer: RX.Styles.createViewStyle({
    height: 200,
    width: 200,
    borderWidth: 3,
    borderRadius: 5,
    borderStyle: 'dashed',
    borderColor: Colors.black,
    opacity: 0.07,
  }),
  addUploadImage: RX.Styles.createImageStyle({
    flex: 1,
    alignSelf: 'center',
    height: 96,
    width: 96,
  }),
  addUploadButton: RX.Styles.createViewStyle({
    marginLeft: 16,
    marginRight: 8,
  }),
  addUploadButtonImage: RX.Styles.createImageStyle({
    height: 20,
    width: 21,
    opacity: 0.7,
    marginRight: 6,
    marginBottom: -5,
  }),
  filedropEmpty: RX.Styles.createViewStyle({
    alignItems: 'center'
  }),
  filedropText: RX.Styles.createTextStyle({
    fontSize: FontSizes.size16,
    fontWeight: '600',
    color: Colors.black,
    textAlign: 'center',
    marginBottom: 32
  }),
  buttonText: RX.Styles.createTextStyle({
    font: Fonts.displayRegular,
    fontSize: FontSizes.size14,
    lineHeight: 67,
    color: Colors.buttonTextColor
  }),
  buttonTextHover: RX.Styles.createTextStyle({
    color: Colors.buttonTextHover
  }),
  uploadText: RX.Styles.createTextStyle({
    fontSize: FontSizes.size12,
    color: Colors.black,
    marginRight: 20,
    textAlign: 'right',
    flexGrow: 1,
    flexShrink: 1,
  }),
  eta: RX.Styles.createTextStyle({
    fontSize: FontSizes.size12,
    lineHeight: 25,
    marginTop: 6,
    color: Colors.menuText,
    flexGrow: 1,
  }),
  progress: RX.Styles.createTextStyle({
    fontSize: FontSizes.size12,
    lineHeight: 25,
    marginTop: 6,
    color: Colors.menuText,
    flexShrink: 1,
  }),
  dark: RX.Styles.createTextStyle({
    color: Colors.uploaderMinimizedText
  }),
  button: RX.Styles.createTextStyle({
    marginRight: 8,
  }),
  begin: RX.Styles.createTextStyle({
    marginRight: 16,
  }),
  bar: RX.Styles.createViewStyle({
    height: 30,
    marginTop: 6,
    marginRight: 16,
    marginLeft: 16,
    position: 'relative',
    flexGrow: 1,
    flexDirection: 'row',
  }),
};

export default class UploaderPanel extends ComponentBase<any/*UploaderPanelProps*/, UploaderPanelState> {
  protected _buildState(props: UploaderPanelProps, initState: boolean): Partial<UploaderPanelState> | undefined {
    let state: Partial<UploaderPanelState> = {};

    state.query = '';
    state.destination = UploaderStore.getFolderKey();
    state.sizeLimit = UploaderStore.getFileLimit();
    state.minimized = UploaderStore.isMinimized();
    state.filedrop = UploaderStore.isFiledrop();
    state.filedropTitle = UploaderStore.getFiledropTitle();
    state.started = UploaderStore.isStarted();
    state.uploads = UploaderStore.getUploads().map((upload, i) => ({
      upload,
      height: 46,
      key: i.toString(),
      template: 'upload',
    }));
    state.view = state.uploads;

    return state;
  }

  componentDidMount() {
    UploaderStore.addSession();
    window.addEventListener('paste', this._onPasteFiles);
    window.addEventListener('message', this._onMessage, false);
    try { parent.postMessage('mf-uploader-init', '*'); } catch(e) {}
  }

  componentWillUnmount() {
    window.removeEventListener('paste', this._onPasteFiles);
  }

  render() {
    const isStarted = this.state.started;
    const isFiledrop = this.state.filedrop;
    const isComplete = this._isUploadSessionFinished();
    if (!isStarted || isComplete) {
      window.onbeforeunload = null;
    } else if (!window.onbeforeunload) {
      window.onbeforeunload = function() {
        return 'Are you sure you want to cancel your upload?';
      };
    }

    if (this.state.minimized) {
      return this._renderMinimizedBar(isComplete);
    }
    return this._renderUploads(isComplete, isFiledrop);
  }

  private _renderMinimizedBar = (isComplete) => {
    const {count, size, hashed, verified, uploaded} = this._getTotalUploadProgress();
    const isEmpty = count === 0;
    const countText = count ? `${count} file${count !== 1 ? 's' : ''}` : '';
    const sizeText = !isEmpty ? bytesize(size) : '0B';

    return (
      <RX.View style={_styles.uploaderMinimized}>
        {this._renderProgressBar(hashed, verified, uploaded, size, countText ? `${countText} — ${sizeText}` : '', true)}
        <Button primary value={'SHOW'} buttonStyle={[_styles.begin, _styles.dark]} onPress={this._onPressMaximize}/>
      </RX.View>
    );
  }

  private _renderEmptyUploader = () => {
    return (
      <RX.View style={_styles.addUploadImageContainer}>
        <RX.Image source={ImageSource.add} style={_styles.addUploadImage} />
      </RX.View>
    );
  }

  private _renderEmptyFiledrop = () => {
    return (
      <RX.View style={_styles.filedropEmpty}>
        {this.state.filedropTitle &&
          <Text style={_styles.filedropText} text={this.state.filedropTitle}/>
        }
        <RX.View style={_styles.addUploadImageContainerFiledrop}>
          <RX.Image source={ImageSource.add} style={_styles.addUploadImage} />
        </RX.View>
      </RX.View>
    );
  }

  private _renderUploads = (isComplete, isFiledrop) => {
    const isStarted = this.state.started;
    const titleText = !isStarted
      ? 'Select Files to Upload'
      : (!isComplete ? 'Uploading Files' : 'Upload Complete');

    return (
      <RX.View
        useSafeInsets
        style={_styles.container}
        onDragEnter={this._onDragEnter}
        onDragLeave={this._onDragLeave}
        onDragOver={this._onDragOver}
        onDrop={this._onDropFiles}
      >
        {!isFiledrop &&
          <AppTopBarStack
            showBuild
            title={titleText}
            canClose={!isStarted || isComplete}
            canMinimize={isStarted}
            onMinimize={this._onPressMinimize}
            onClose={this._onPressCancel}
          />
        }
        {this.state.view.length
          ? <RX.View style={_styles.list}>
              <VirtualListView
                itemList={this.state.view}
                renderItem={this._renderUpload}
              />
            </RX.View>
          : <RX.View style={_styles.listNone}>
              <input multiple type="file" onChange={this._onBrowseFiles}/><label/>
              {isFiledrop ? this._renderEmptyFiledrop() : this._renderEmptyUploader()}
            </RX.View>
        }
        {!isStarted && !isComplete && this._renderFooterAddFiles()}
        {isStarted && !isComplete && this._renderFooterUploading()}
        {isComplete && this._renderFooterComplete()}
      </RX.View>
    );
  }

  private _renderUpload = (item: UploaderItemInfo, hasFocus?: boolean) => {
    return (
      <UploaderItem
        upload={item.upload}
        isUploading={this.state.started}
        isSelected={item.upload.id === this.props.selectedUploadId}
        query={this.state.query}
        onPress={this._onPressItem}
      />
    );
  };

  private _renderFooterAddFiles = () => {
    const {count, size} = this._getTotalUploadProgress();
    const isEmpty = count === 0;
    const countText = count ? `${count} file${count !== 1 ? 's' : ''}` : '';
    const sizeText = !isEmpty ? bytesize(size) : '0B';
    const isBrowseFolderSupported = navigator.userAgent.indexOf('Chrome/') !== -1 ||
      navigator.userAgent.indexOf('Firefox/') !== -1;
    const isMobile = navigator.userAgent.indexOf('Mobile') !== -1
      || navigator.userAgent.indexOf('Tablet') !== -1;
    return (
      <RX.View style={_styles.uploaderHeader}>
        {this._renderFileButton()}
        {!isMobile && isBrowseFolderSupported && this._renderFolderButton()}
        <Text style={_styles.uploadText} text={!isMobile && countText ? `${countText} — ${sizeText}` : ''}/>
        <Button outline value={'CANCEL'} buttonStyle={_styles.button} onPress={this._onPressCancel}/>
        <Button primary value={isMobile ? 'UPLOAD' : 'BEGIN UPLOAD'} buttonStyle={_styles.begin} onPress={this._onPressBegin} disabled={isEmpty}/>
      </RX.View>
    );
  }

  private _renderFooterUploading = () => {
    const {count, size, hashed, verified, uploaded} = this._getTotalUploadProgress();
    const totalText = uploaded > 0
      ? `${bytesize(Math.min(size, uploaded))} / ${bytesize(size)}`
      : `Preparing ${count} file${count !== 1 ? 's' : ''}…`;
    return (
      <RX.View style={_styles.uploaderHeader}>
        {this._renderProgressBar(hashed, verified, uploaded, size, totalText)}
        <Button outline value={'CANCEL'} buttonStyle={_styles.button} onPress={this._onPressCancel}/>
      </RX.View>
    );
  }

  private _renderFooterComplete = () => {
    const {uploaded, finished, fileSizeExceeded, virusFlagged} = this._getTotalUploadProgress();
    const isSubFolder = this.state.destination && this.state.destination !== 'myfiles';
    const isMobile = navigator.userAgent.indexOf('Mobile') !== -1
      || navigator.userAgent.indexOf('Tablet') !== -1;
    const totalText = !isMobile && finished > 0 && uploaded > 0
      ? `Uploaded ${finished} file${finished !== 1 ? 's' : ''} — ${bytesize(uploaded)}`
      : '';

    if (fileSizeExceeded) {
      try { parent.postMessage('mf-filesize-limit', '*'); } catch(e) {}
    }

    if (virusFlagged) {
      try { parent.postMessage('mf-virus-detected', '*'); } catch(e) {}
    }

    return (
      <RX.View style={_styles.uploaderHeader}>
        <Text style={_styles.uploadText} text={totalText}/>
        {isSubFolder && <Button primary value={'SHARE FOLDER'} buttonStyle={_styles.button} onPress={this._onPressCopy}/>}
        <Button outline value={'DONE'} buttonStyle={_styles.button} onPress={this._onPressCancel}/>
      </RX.View>
    );
  }

  private _renderProgressBar = (
    hashed: number,
    verified: number,
    uploaded: number,
    size: number,
    eta: string,
    dark: boolean = false
  ) => {
    const started = uploaded > 0 || hashed > 0;
    const bytes = started ? uploaded > 0 ? uploaded : hashed : 0;
    const percent = started ? Math.max(0, Math.min(100, (bytes / size) * 100)) : 0;
    const barBackground = dark ? Colors.uploaderBarBackground : Colors.white;
    const hashBackground = dark ? Colors.uploaderBarHash : Colors.buttonGreyBackgroundHover;
    const totalText = `${verified} file${verified !== 1 ? 's' : ''}`;
    const stateText = uploaded < size
      ? `${Math.round(percent)}%`
      : (verified > 0 ? `Verifying ${totalText}…` : '');
    return (
      <RX.View style={_styles.bar}>
        <div style={{width: '100%', height: 6, backgroundColor: barBackground, position: 'absolute'}} />
        <div style={{width: (hashed / size) * 100 + '%', height: 6, backgroundColor: hashBackground, position: 'absolute', transition: '0.2s width'}} />
        <div style={{width: (uploaded / size) * 100 + '%', height: 6, backgroundColor: Colors.buttonBlueBackground, position: 'absolute', transition: '0.2s width'}}/>
        <Text style={dark ? [_styles.eta, _styles.dark] : _styles.eta} text={eta}/>
        <Text style={dark ? [_styles.progress, _styles.dark] : _styles.progress} text={stateText}/>
      </RX.View>
    );
  }

  private _renderFileButton = () => {
    return (
      <RX.View style={_styles.addUploadButton}>
        <input ref="prompt" type="file" onChange={this._onBrowseFiles} multiple/><label/>
        <RX.Text style={_styles.buttonText}>
          <RX.Image source={ImageSource.fileNew} style={_styles.addUploadButtonImage}/>
          {'Add file'}
        </RX.Text>
      </RX.View>
    );
  }

  private _renderFolderInput = () => {
    // @ts-ignore
    return <input type="file" onChange={this._onBrowseFiles} directory={'true'} webkitdirectory={'true'} mozdirectory={'true'}/>;
  }

  private _renderFolderButton = () => {
    return (
      <RX.View style={_styles.addUploadButton}>
        {this._renderFolderInput()}<label/>
        <RX.Text style={_styles.buttonText}>
          <RX.Image source={ImageSource.folderNew} style={_styles.addUploadButtonImage}/>
          {'Add folder'}
        </RX.Text>
      </RX.View>
    );
  }

  private _getTotalUploadProgress() {
    const count = this.state.view.length;
    let size = 0;
    let uploaded = 0;
    let hashed = 0;
    let finished = 0;
    let verified = 0;
    let fileSizeExceeded = false;
    let virusFlagged = false;

    this.state.view.forEach((uploadInfo) => {
      const {upload} = uploadInfo;
      const disabled = upload.state === UploadFileStates.Failed || upload.state === UploadFileStates.Skipped;
      const complete = upload.state === UploadFileStates.Finished;
      const verifying = upload.state === UploadFileStates.Verifying;
      
      // @ts-ignore
      if (upload.ref.exceededSizeLimit) fileSizeExceeded = true;
      // @ts-ignore
      if (upload.ref.virusFlagged) virusFlagged = true;
      if (disabled) return;
      size += (upload.size || 0);
      hashed += upload.progress ? upload.progress.hashed : 0;
      uploaded += complete ? upload.size : (upload.progress ? upload.progress.uploaded : 0);
      if (complete) finished++;
      if (verifying) verified++;
    });

    return {
      count,
      size,
      uploaded,
      hashed,
      finished,
      verified,
      fileSizeExceeded,
      virusFlagged,
    };
  }

  private _isUploadSessionFinished(): boolean {
    if (!this.state.view.length) return false;
    const uploadsInfo = this.state.view;
    let isUploading = false;
    for (let i = 0; i < uploadsInfo.length; i++) {
      const {upload} = uploadsInfo[i];
      if (upload.state !== UploadFileStates.Finished
        && upload.state !== UploadFileStates.Failed
        && upload.state !== UploadFileStates.Skipped) {
        isUploading = true;
      }
    }
    return !isUploading;
  }

  private _onPressItem = (upload: UploadFile) => {
    // ... user pressed upload item row
  };

  private _onPressCancel = () => {
    UploaderStore.resetSession();
    try{
      parent.postMessage('mf-uploader-close', '*');
      parent.document.getElementById('uploader_window_popup').style.display='none';
    }catch(e){}
  };

  private _onPressMaximize = () => {
    UploaderStore.maximize();
  };

  private _onPressMinimize = () => {
    UploaderStore.minimize();
  };
  
  private _onPressBegin = () => {
    UploaderStore.startSession();
  };

  private _onPressCopy = (e?: RX.Types.SyntheticEvent) => {
    e && e.stopPropagation();
    copyLink(this.state.destination);
  }

  private _onBrowseFiles = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    this._addFiles(e.target.files);
  }

  private _onDropFiles = (e: any) => {
    const self = this;
    e.preventDefault();
    e.stopPropagation();
    const items = e.dataTransfer.items;
    const files = e.dataTransfer.files;
    if ((!files || !files.length) && (!items || !items.length))
      return;
    this._appendFolderPathToFiles(items, files, function(allFiles) {
      self._addFiles(allFiles);
    });
  }

  private _onPasteFiles = (e: ClipboardEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.clipboardData.files && e.clipboardData.files.length) {
      this._addFiles(e.clipboardData.files);
      return;
    }

    const items = e.clipboardData.items;
    const files = [];
    for (let i = 0; i < items.length; i++) {
      try {
        const file = items[i].getAsFile();
        if (file) files.push(file);
      } catch (e) {}
    }

    if (!files.length) return;
    this._addFiles(files);
  }

  private _processUploadEvent(data) {
    // Show uploader
    UploaderStore.maximize();
    // Same folder, add to uploads
    if (data.folder === this.state.destination) {
      if (data.files) {
        if (this._addFiles(data.files)) {
          UploaderStore.startSession();
        }
      }
    }
  }

  private _onMessage = (e) => {
    if (typeof window !== 'undefined' && window['__MF_UPLOADER_INIT__']) {
      this._processUploadEvent(window['__MF_UPLOADER_INIT__']);
    }

    switch (e.data.event) {
      case 'prompt':
        // @ts-ignore
        if (this.refs.prompt) this.refs.prompt.click();
        return;
      case 'show':
        UploaderStore.maximize();
        return;
      case 'add':
        this._processUploadEvent(e.data);
        return;
    }
  }

  private _addFiles(files) {
    let emptyUpload = true;
    for (let i = 0; i < files.length; i++) {
      const name = files[i].name.toLowerCase();
      if (files[i].size !== 0
        && name !== '.ds_store'
        && name !== 'thumbs.db'
        && name !== 'desktop.ini'
      ) {
        emptyUpload = false;
      }
      if (files[i].size > this.state.sizeLimit) {
        files[i].exceededSizeLimit = true;
      }
    }

    if (emptyUpload) {
      return false;
    }

    UploaderStore.addUploads(files);
    return true;
  }

  private _appendFolderPathToFiles(items, original, callback) {
    // The goal of this function is to mimic the affects of uploading folders through the folder browse input
    // it converts webkitEntries to an Html5FileObject,
    // and adds a folderpath to match the functionality of using a folder browse input,
    // currently Chrome only (webkitGetAsEntry).
    const files = [];
    const entries = [];
    let counter = 0;
    let directoryFound = false;

    const toArray = (list) => Array.prototype.slice.call(list || [], 0);
    const errorHandler = (e) => {};
    const getAllEntries = (reader, callback) => {
      let entries = [];
      const readEntries = function() {
        counter++;
        reader.readEntries(function(results) {
          if (!results.length) {
            entries.sort();
            counter--;
            callback(entries);
          } else {
            entries = entries.concat(toArray(results));
            counter--;
            readEntries();
          }
        }, errorHandler);
      };
      readEntries();
    }
    const readDirectory = (entries) => {
      if (entries <= 0) callback(files);
      for (let i = 0; i < entries.length; i++) {
        if (entries[i]) {
          if (entries[i]['isDirectory']) {
            const reader = entries[i].createReader();
            getAllEntries(reader, readDirectory);
          } else {
            // Path to the current directory (first entry path in directory)
            let folderPath = entries[i].fullPath.slice(1).split('/'); // Remove starting slash.
            folderPath.pop(); // Remove filename.
            folderPath = folderPath.join('/');
            // Creates a file object from the entry.
            counter++;
            entries[i].file(function(file) {
            counter--;
            // Attach the url so we know where its located.
            file.MFRelativePath = folderPath + '/' + file.name;
            // Add the file to the array.
            files.push(file);
            // Check if we are completely done.
            if (counter <= 0) callback(files);
            }, errorHandler);
          }
        }
      }
    };

    if (items) {
      for (let i = 0; i < items.length; i++) {
        if (!items[i].webkitGetAsEntry) break;
        entries[i] = items[i].webkitGetAsEntry();
        if (entries[i] && entries[i]['isDirectory'])
          directoryFound = true;
        }
    }

    if (directoryFound)
      readDirectory(entries);
    else
      callback(original);
  }

  private _onDragEnter = (e?: any) => {
    e && e.preventDefault();
  }

  private _onDragOver = (e?: any) => {
    e && e.preventDefault();
    e && e.stopPropagation();
  }

  private _onDragLeave = (e?: any) => {
    e && e.preventDefault();
  }
}
