/*******************************************************************************
 * @name UploaderModels
 * @author MediaFire <cavitt.glover@mediafire.com>
 * @description Data models used within the "uploader" view.
 ******************************************************************************/

export interface UploadFile {
  id: number;
  ref: File;
  state: UploadFileStates;
  size: number;
  name: string;
  type: string;
  quickkey?: string | null;
  duplicate?: string | null;
  units?: UploadFileUnits;
  progress?: UploadFileProgress;
  flags?: UploadFileFlags[];
  dates?: UploadFileDates;
  error: Error | null;
}

export interface UploadSession {
  id: number;
  files: UploadFile[];
  _transport: Object | null;
}

export enum UploadFileFlags {
  StorageExceeded,
  Virus,
}

export enum UploadFileStates {
  Queued,
  Hashing,
  Hashed,
  Checking,
  Duplicate,
  Waiting,
  Uploading,
  Verifying,
  Finished,
  Failed,
  Skipped,
}

export interface UploadFileUnits {
  size: number;
  units: boolean[];
}

export interface UploadFileProgress {
  hashed: number;
  uploaded: number;
  speed: number;
  remaining: number;
}

export interface UploadFileDates {
  created: Date | null;
  modified: Date | null;
  eta: Date | null;
}
