export function repeat(text: string, count: number) {
  if (count < 1) return '';
  let result = '';
  while (count > 1) {
    if (count & 1) result += text;
    count >>= 1, text += text;
  }
  return result + text;
}

export function pad(text: string, width: number, inverse = false, pattern = ' ') {
  return inverse
    ? (text + repeat(pattern, width)).slice(0, width)
    : (repeat(pattern, width) + text).slice(-(width));
}

export function truncate(text: string, limit: number) {
  const exceeded = text.length > limit;
  return text.substr(0, limit - (exceeded ? 2 : 0))
    + (exceeded ? '…' : '');
}

export function bytesize(bytes: number, decimals: number = 2) {
  if (bytes == 0) return '0B';
  const k = 1024;
  const dm = decimals <= 0 ? 0 : decimals;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return (bytes / Math.pow(k,i)).toFixed(dm) + sizes[i];
}
