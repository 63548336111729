(function() {
    "use strict";

    function MFUploader(sActionToken, oCallbacks, oConfig) {
        // Config Options:
        // oCallbacks.onUpdate
        // oCallbacks.onUploadProgress
        // oCallbacks.onHashProgress
        // oCallbacks.onDuplicateConfirm
        // oConfig.folderkey
        // oConfig.apiUrl
        // oConfig.apiVersion
        // oConfig.uploadUrl
        // oConfig.resourcePath
        // oConfig.concurrentUploads
        // oConfig.retryAttempts
        // oConfig.disableInstantUploads
        // oConfig.actionOnDuplicate
        // oConfig.returnThumbnails (true/false)
        // oConfig.filterByExtension (string or array)
        // oConfig.reporter
        // oConfig.filedrop
        oConfig = oConfig || {};

        // Constants
        this.EVENT_FILE_STATE = 0;
        this.EVENT_UPLOAD_PROGRESS = 1;
        this.EVENT_HASH_PROGRESS = 2;
        this.EVENT_DUPLICATE = 3;

        this.TYPE_UNDETERMINED = 0;
        this.TYPE_INSTANT = 1;
        this.TYPE_RESUMABLE = 2;

        this.FILE_STATE_HASH_QUEUED = 'hash-queue';
        this.FILE_STATE_HASHING = 'hashing';
        this.FILE_STATE_HASHED = 'hashed';
        this.FILE_STATE_UPLOAD_CHECK = 'pre-upload';
        this.FILE_STATE_UPLOAD_QUEUED = 'upload-queue';
        this.FILE_STATE_UPLOADING = 'uploading';
        this.FILE_STATE_VERIFYING = 'verifying';
        this.FILE_STATE_COMPLETE = 'complete';
        this.FILE_STATE_DUPLICATE = 'duplicate';
        this.FILE_STATE_ABORTED = 'aborted';
        this.FILE_STATE_SKIPPED = 'skipped';
        this.FILE_STATE_FAILED = 'failed';

        // Do not return data url if over this size (5MB)
        this.THUMB_SIZE_LIMIT = 5*1024*1024;

        // Store callbacks
        this._callbacks = [];
        if (oCallbacks) {
            this._callbacks[this.EVENT_FILE_STATE] = oCallbacks.onUpdate;
            this._callbacks[this.EVENT_UPLOAD_PROGRESS] = oCallbacks.onUploadProgress;
            this._callbacks[this.EVENT_HASH_PROGRESS] = oCallbacks.onHashProgress;
            this._callbacks[this.EVENT_DUPLICATE] = oCallbacks.onDuplicateConfirm;
        }

        // Error reporter
        this._reporter = oConfig.reporter || null;

        // Valid action token required
        if (!sActionToken && !oConfig.filedrop) {
            throw new Error('Missing or invalid action token was supplied');
        }

        // Check for core feature support
        if (!MFUploader.isSupported(null)) {
            throw new Error('This browser does not support HTML5 uploads');
        }

        this._filedropKey = oConfig.filedrop;
        this._actionToken = sActionToken;
        this._apiUrl = (oConfig.apiUrl || '//mediafire.com/api/')
            + (oConfig.apiVersion ? oConfig.apiVersion + '/' : '');
        this._resourcePath = oConfig.resourcePath || '';
        this._uploadUrl = oConfig.uploadUrl || this._apiUrl;
        // Save optional config
        this._options = oConfig;

        // Internal file list
        this.files = [];
        this._aXHRs = [];
        this._activeUploads = 0;
        this._uploadQueue = [];
        this._waitingToStartUpload = [];
        this._uploaderStarted = false;
        this._uploadOnAdd = oConfig.uploadOnAdd;
        this.progress = null;

        // Duplicate actions
        this._actionOnDuplicate = oConfig.actionOnDuplicate;
        this._awaitingDuplicateAction = false;
        this._duplicateConfirmQueue = [];

        // Load the hasher
        this._hasherQueue = [];
        this._hasherReady = false;
        this._hasher = null;
        this._loadHasher();
    }

    MFUploader.isSupported = function(sTestCase) {
        var oTests = {
            filereader: typeof FileReader !== 'undefined',
            formdata: !!window.FormData,
            webworker: !!window.Worker,
            // This test will determine if we can return a progress meter
            progress: 'upload' in new XMLHttpRequest()
        };

        // No test case specified, default to the required three
        if (!sTestCase) {
            return oTests.filereader && oTests.formdata && oTests.webworker;
        } else {
            return oTests[sTestCase];
        }
    };

    MFUploader.isImage = function(type) {
        return type && type.substr(0,6) === 'image/';
    };

    MFUploader.getUnitSize = function(iFileSize) {
        for (var i = 0; i <= 7; i++) {
            // 0x400000 = 4MB
            if (iFileSize < 0x400000 * Math.pow(4, i) || i === 7) {
                // 0x100000 = 1MB
                return (i === 0 ? 0x400000 : (0x100000 * Math.pow(2, i - 1)));
            }
        }
    };

    MFUploader.getBytesUploaded = function(oFile) {
        var iBytes = 0;
        var iLastUnitSize = oFile.size % oFile.unitSize;
        var iTotalUnits = oFile.units.length;

        // Increment uploaded bytes
        oFile.units && oFile.units.forEach(function(bUploaded, iUnit) {
            if (bUploaded) {
                // The last unit is not guaranteed to be full.
                iBytes += (iUnit === iTotalUnits - 1)
                    ? iLastUnitSize
                    : oFile.unitSize;
            }
        });
        return Math.max(0, Math.max(100, iBytes));
    };

    MFUploader.getTimeSince = function(iCurrent, iPrevious) {
        var iMinute = 60 * 1000;
        var iHour = iMinute * 60;
        var iDay = iHour * 24;
        var iMonth = iDay * 30;
        var iYear = iDay * 365;
        var iElapsed = current - previous;
        if (iElapsed < iMinute) {
            return Math.round(iElapsed/1000) + ' seconds ago';
        } else if (iElapsed < iHour) {
            return Math.round(iElapsed/iMinute) + ' minutes ago';
        } else if (iElapsed < iDay ) {
            return Math.round(iElapsed/iHour) + ' hours ago';
        } else if (iElapsed < iMonth) {
            return Math.round(iElapsed/iDay) + ' days ago';
        } else if (iElapsed < iYear) {
            return Math.round(iElapsed/iMonth) + ' months ago';
        } else {
            return Math.round(iElapsed/iYear) + ' years ago';
        }
    };

    MFUploader.getNearestUnitSize = function(iBytes) {
        if (iBytes < 1000) return iBytes + 'B';
        if (iBytes < 1000000) return (iBytes / 1000).toFixed(2) + 'KB';
        if (iBytes < 1000000000) return (iBytes / 1000000).toFixed(2) + 'MB';
        return (iBytes / 1000000000).toFixed(2) + 'GB';
    };

    MFUploader.getUnits = function(oBitmap) {
        var aUnits = [];
        for (var i = 0; i < oBitmap.count; i++) {
            var iWord = parseInt(oBitmap.words[i], 10);
            var sBin = iWord.toString(2);
            while(sBin.length < 16) {
                sBin = '0' + sBin;
            }
            for(var b = 0; b < sBin.length; b++) {
                aUnits[i * 16 + b] = (sBin[15 - b] === '1');
            }
        }
        return aUnits;
    };

    MFUploader.sortFiles = function(aFiles) {
        aFiles.sort(function(a, b) {
            return a.size - b.size;
        });
    };

    MFUploader.getRandom = function(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
    };

    MFUploader.sanitizeFilename = function(filename, encode) {
        const validname = filename
            .replace('|', '-')
            .replace(':', '-')
            .replace('*', '-')
            .replace('?', '-')
            .replace('"', '-')
            .replace('<', '-')
            .replace('>', '-')
            .replace('/', '-')
            .replace('\\', '-')
            .trim();
        return encode ? unescape(encodeURIComponent(validname)) : validname;
    }

    MFUploader.prototype._loadHasher = function() {
        var self = this;
        window.MFHasherReady = {
            init: false,
            exec: function() {
                self._hasher = new MFHasher({resourcePath: self._resourcePath});
                self._hasherReady = true;
                self._syncHasher();
            }
        };
        var script = document.createElement('script');
        script.async = true;
        script.type = 'text/javascript';
        script.src = this._resourcePath + 'hasher.2.0.1.js';
        document.body.appendChild(script);
    };

    MFUploader.prototype._syncHasher = function() {
        if (!this._hasherReady) return;
        if (!this._hasherQueue || !this._hasherQueue.length) return;
        this._hasherQueue.sort(function(a, b) {return a[0].size - b[0].size;});
        while (this._hasherQueue.length) {
            var file = this._hasherQueue.shift();
            if (file) this._hasher.addFile.apply(this._hasher, file);
        }
        this._hasherQueue = [];
    };

    MFUploader.prototype._log = function(message, data) {
        if (!this._reporter) return;
        var payload;
        try { payload = JSON.stringify(data); } catch(e) { payload = '{}'; }
        this._reporter.leaveBreadcrumb(message, JSON.parse(payload));
    };

    MFUploader.prototype._report = function(error, oFile) {
        if (!this._reporter) return;

        var sampleNetworkRate = 0.5;
        var sample = MFUploader.getRandom(1, 100);
        var isWithinSample = sample < sampleNetworkRate;
        var isNetworkError = typeof error === 'string'
            && error.indexOf('Network error, out of retries') !== -1;

        // Sample network errors
        if (isNetworkError && !isWithinSample) return;

        this._reporter.notify(error, {
            context: typeof error === 'string' ? error : undefined,
            metaData: {
                file: {
                    quickkey: oFile.quickkey,
                    hash: oFile.hashes ? oFile.hashes.full : null,
                    hashed: oFile.bytesHashed,
                    uploaded: oFile.bytesUploaded,
                    retries: oFile.uploadRetries,
                },
                connection: {
                    downlink: navigator.connection ? navigator.connection.downlink : null,
                    effectiveType: navigator.connection ? navigator.connection.effectiveType : null,
                    rtt: navigator.connection ? navigator.connection.rtt : null,
                    saveData: navigator.connection ? navigator.connection.saveData : null,
                },
                hardware: {
                    memory: navigator.deviceMemory ? navigator.deviceMemory : null,
                    threads: navigator.hardwareConcurrency ? navigator.hardwareConcurrency : null,
                    cookies: navigator.cookieEnabled ? navigator.cookieEnabled : null,
                    touchpoints: navigator.maxTouchPoints ? navigator.maxTouchPoints : null,
                },
            }
        });
    };

    MFUploader.prototype._emit = function(iEvent, oFile, oData, sQuickkey, sDupeQuickkey, oError) {
        if ((iEvent === this.EVENT_UPLOAD_PROGRESS || iEvent === this.EVENT_HASH_PROGRESS)
            && (oFile.state === this.FILE_STATE_VERIFYING || oFile.state === this.FILE_STATE_COMPLETE))
            return;

        var emit = function() {
            if (this._callbacks[iEvent]) {
                this._callbacks[iEvent](this, oFile, oData || oFile.state, sQuickkey, sDupeQuickkey, oError);
            }
        };
        setTimeout(emit.bind(this), 0);
    };

    MFUploader.prototype._augmentFile = function(oFile) {
        oFile.quickkey = null;
        oFile.dupeQuickkey = null;
        oFile.unitSize = MFUploader.getUnitSize(oFile.size);
        oFile.unitBytesUploaded = {};
        oFile.bytesHashed = 0;
        oFile.bytesUploaded = 0;
        oFile.uploadRetries = 0;
        oFile.virusFlagged = false;
        oFile.exceededStorage = false;
        oFile.state = this.FILE_STATE_HASH_QUEUED;
        oFile.uploadType = this.TYPE_UNDETERMINED;
        oFile.dataURL = false;
        oFile.XHRs = [];

        var sPath = oFile.webkitRelativePath
            || oFile.mozRelativePath
            || oFile.MFRelativePath
            || '';
        if (sPath) {
            var aPathData = sPath.split('/');
            aPathData.pop();
            oFile.path = aPathData.join('/');
        }

        this._emit(this.EVENT_FILE_STATE, oFile, oFile.state, oFile.quickkey, oFile.dupeQuickkey);
    };

    MFUploader.prototype._apiRequest = function(sAction, oParams, oCallbacks) {
        // Record api network requests
        var oXHR = new XMLHttpRequest();
        this._aXHRs.push(oXHR);

        // Default api params
        oParams = oParams || {};
        if (this._filedropKey)
            oParams.filedrop_key = this._filedropKey;
        else
            oParams.session_token = this._actionToken;
        oParams.response_format = 'json';

        // Build query string
        var sQuery = '?' + Object.keys(oParams).map(function(sKey) {
            return [sKey, oParams[sKey]].map(encodeURIComponent).join('=');
        }).join('&');

        // Bypass cache with date timestamp
        sQuery += ('&' + Date.now());

        // Events: load, progress, error, abort
        if (oCallbacks) {
            Object.keys(oCallbacks).forEach(function(sKey) {
                oXHR.addEventListener(sKey, oCallbacks[sKey], false);
            });
        }

        oXHR.open('GET', this._apiUrl + 'upload/' + sAction + '.php' + sQuery, true);
        oXHR.send();

        return oXHR;
    };

    MFUploader.prototype._nextUploads = function() {
        // Uploads in queue and upload slots available
        while (this._uploadQueue.length > 0
            && this._activeUploads < (this._options.concurrentUploads || 3)) {
            this._uploadUnit.apply(this, this._uploadQueue.shift());
        }
    };

    MFUploader.prototype._uploadUnit = function(oFile, iUnit, sDuplicateAction) {
        if (!oFile) return;

        // Search for unit in queue
        var bUnitQueued = false;
        for (var i = 0; i < this._uploadQueue.length; i++) {
            var oQueue = this._uploadQueue[i];
            if (oQueue && oQueue[0] === oFile && oQueue[1] === iUnit) {
                bUnitQueued = true;
                break;
            }
        }

        // Already queued
        if (bUnitQueued) return;

        // TEMP: disable multiple uploads for files w/ paths
        if (oFile.path) {
            this._options.concurrentUploads = 1;
        }

        // Queue unit upload
        var uploadLimit = this._options.concurrentUploads || 3;
        if (this._activeUploads >= uploadLimit) {
            this._uploadQueue.push([oFile, iUnit]);
            return;
        }

        // Free upload thread available
        this._activeUploads++;
        oFile.state = this.FILE_STATE_UPLOADING;
        this._emit(this.EVENT_FILE_STATE, oFile, oFile.state, oFile.quickkey, oFile.dupeQuickkey);

        var oXHR = new XMLHttpRequest();
        var oThis = this;

        // Record upload network requests
        this._aXHRs.push(oXHR);
        oFile.XHRs.push(oXHR);

        // Default upload params
        var oParams = {};
        oParams.response_format = 'json';
        if (this._filedropKey) {
            oParams.filedrop_key = this._filedropKey;
        } else {
            oParams.session_token = this._actionToken;
            oParams.uploadkey = this._options.folderkey || 'myfiles';
        }

        // Relative path specified
        if (oFile.path)
            oParams.path = oFile.path;

        // Duplicate action is global or specified explicitly
        if (sDuplicateAction || this._actionOnDuplicate)
            oParams.action_on_duplicate = (sDuplicateAction || this._actionOnDuplicate);

        // Build query string
        var sQuery = '?' + Object.keys(oParams).map(function(sKey) {
            return [sKey, oParams[sKey]].map(encodeURIComponent).join('=');
        }).join('&');

        // Slice blob from file
        var oUnitBlob = oFile.slice(iUnit * oFile.unitSize, (iUnit + 1) * oFile.unitSize);

        // Set to false on upload success so the
        // timeout or error handler don't run
        var bCompletionFunctionRan = false;

        // Unit successfully uploaded
        var fUploadSuccess = function(sUploadKey) {
            if (bCompletionFunctionRan) return;
            bCompletionFunctionRan = true;

            oThis._activeUploads--;

            // Upload next file if any in queue
            oThis._nextUploads();

            // Update file state
            oFile.units[iUnit] = true;
            oFile.uploadKey = sUploadKey;
            oFile.uploadRetries = 0;

            // Finished uploading file
            if (oFile.units.filter(function(unit) { return !unit; }).length === 0) {
                oFile.bytesUploaded = oFile.size;
                oThis._emit(oThis.EVENT_UPLOAD_PROGRESS, oFile, oFile.bytesUploaded);
                oFile.state = oThis.FILE_STATE_VERIFYING;
                oThis._emit(oThis.EVENT_FILE_STATE, oFile, oFile.state, oFile.quickkey, oFile.dupeQuickkey);
                oThis._pollUpload(oFile);
            }
        };

        // Unit failed at some point
        var fUploadFailed = function(oErrorData) {
            if (bCompletionFunctionRan) return;
            bCompletionFunctionRan = true;

            oThis._activeUploads--;

            oThis._log('Network failure.', oErrorData);

            // Retry unit if we can
            if (oFile.uploadRetries < (oThis._options.retryAttempts || 3)) {
                oFile.uploadRetries++;
                oThis._uploadQueue.push([oFile, iUnit]);
            // Out of retries, fail
            } else {
                // Update file state
                oFile.unitBytesUploaded[iUnit] = 0;
                oFile.state = oThis.FILE_STATE_FAILED;
                var serverError = oThis._getFailureError(oErrorData, 'Network error, out of retries.');
                oThis._report(serverError, oFile);
                oThis._emit(oThis.EVENT_FILE_STATE, oFile, oFile.state, oFile.quickkey, oFile.dupeQuickkey, serverError);
            }

            // Upload next file if any in queue
            oThis._nextUploads();
        };

        var iTimeoutCheckSeconds = 15;  // Check every...
        var iTimeoutMaxSeconds = 60*5;
        var iTimeoutSecondsSinceProgress = 0;
        var iTimeoutLoaded = 0; // Store "progress" event "loaded" byte size here
        var iTimeoutLastLoaded = 0;  // Last loaded size when
        var fHandleTimeout = function() {
            if (bCompletionFunctionRan) return;

            if (iTimeoutLoaded === iTimeoutLastLoaded) {
                iTimeoutSecondsSinceProgress += iTimeoutCheckSeconds;  // Increment count
            } else {
                iTimeoutSecondsSinceProgress = 0;  // Reset timer
                iTimeoutLastLoaded = iTimeoutLoaded;
            }

            if (iTimeoutMaxSeconds <= iTimeoutSecondsSinceProgress) {
                oXHR.abort();
                fUploadFailed('Connection timeout');
            } else {
                setTimeout(fHandleTimeout, iTimeoutCheckSeconds*1000);  // Keep going
            }
        };
        setTimeout(fHandleTimeout, iTimeoutCheckSeconds*1000);

        try {
            // Determine success or error of the upload
            oXHR.onreadystatechange = function() {
                if (oXHR.readyState === 4) {
                    if (oXHR.status === 200) {
                        var oResponse = oThis._parseResponse(oXHR.responseText);
                        var oData = oResponse.data;
                        if (oResponse.valid
                            && oData
                            && oData.response.doupload.result === "0") {
                            fUploadSuccess(oData.response.doupload.key);
                        } else {
                            fUploadFailed(oResponse);
                        }
                    } else {
                        fUploadFailed(oXHR);
                    }
                }
            };

            // Update bytes
            oXHR.upload.addEventListener('progress', function(oEvent) {
                if (oEvent.lengthComputable) {
                    // Update total bytes uploaded
                    if (!oFile.unitBytesUploaded[iUnit])
                        oFile.unitBytesUploaded[iUnit] = 0;
                    oFile.unitBytesUploaded[iUnit] = oEvent.loaded || 0;

                    // Aggregate unit progress
                    let bytesUploaded = 0;
                    Object.keys(oFile.unitBytesUploaded).forEach(function(iUnit) {
                        bytesUploaded += oFile.unitBytesUploaded[iUnit];
                    });

                    oThis._emit(oThis.EVENT_UPLOAD_PROGRESS, oFile, oFile.bytesUploaded + bytesUploaded);
                    iTimeoutLoaded = oEvent.loaded || 0;
                } else {
                    // Unable to compute progress information since the total size is unknown.
                    // Assume progress event means actual progress.
                    iTimeoutLoaded += 1;
                }

            }, false);

            var sURL = oFile.upload ? oFile.upload.resumable : this._uploadUrl + 'upload/resumable.php';
            oXHR.open('POST', sURL + sQuery, true);
            oXHR.setRequestHeader('Content-Type', 'application/octet-stream');
            oXHR.setRequestHeader('X-Filename', MFUploader.sanitizeFilename(oFile.name, true));
            oXHR.setRequestHeader('X-Filesize', oFile.size);
            oXHR.setRequestHeader('X-Filetype', oFile.type || 'application/octet-stream');
            oXHR.setRequestHeader('X-Filehash', oFile.hashes.full);
            oXHR.setRequestHeader('X-Unit-Id', iUnit);
            oXHR.setRequestHeader('X-Unit-Hash', oFile.hashes.units[iUnit]);
            oXHR.setRequestHeader('X-Unit-Size', oUnitBlob.size);
            oXHR.send(oUnitBlob);
        } catch(e) {
            fUploadFailed(e);
        }
    };

    MFUploader.prototype._uploadCheck = function(oFile) {
        var oThis = this;
        var oParams = {
            hash: oFile.hashes.full,
            size: oFile.size,
            filename: MFUploader.sanitizeFilename(oFile.name),
            unit_size: oFile.unitSize,
            resumable: 'yes',
            preemptive: 'yes',
        };

        if (oFile.path)
            oParams.path = oFile.path;
        if (!this._filedropKey && this._options.folderkey)
            oParams.folder_key = this._options.folderkey;

        oFile.XHRs.push(this._apiRequest('check', oParams, {
            load: function(evt) {
                var oResponse = oThis._parseResponse(this.responseText);
                var oData = oResponse.data;
                if (!oData || !oResponse.valid) {
                    // Retry in 1 second
                    setTimeout(function() { oThis._uploadCheck(oFile); }, 1000);
                    oThis._log('Check response invalid.', evt);
                    return;
                }

                // Upload URLs available
                if (oData.response && oData.response.upload_url) {
                    oFile.upload = {
                        simple: oData.response.upload_url.simple,
                        resumable: oData.response.upload_url.resumable
                    };
                }

                // Preemptive key available
                if (oData.response && oData.response.preemptive_quickkey) {
                    oFile.quickkey = oData.response.preemptive_quickkey;
                }

                // Instant uploads enabled and hash exists, mark as instant upload
                var disableInstantUploads = oThis._options.disableInstantUploads;
                /* TEMP */ if (oFile.path) disableInstantUploads = true; // Disable multiple uploads for files w/ paths
                if (!disableInstantUploads && oData.response.hash_exists === "yes") {
                    oFile.uploadType = oThis.TYPE_INSTANT;

                // Otherwise, it is a resumable upload
                } else if (oData.response.resumable_upload) {
                    oFile.uploadType = oThis.TYPE_RESUMABLE;
                    var iUnits = parseInt(oData.response.resumable_upload.number_of_units, 10);
                    if (disableInstantUploads) {
                        oFile.units = [];
                        for (var i = 0; i < iUnits; i++) oFile.units[i] = false;
                        oFile.bytesUploaded = 0;
                    } else {
                        // Save unit states
                        oFile.units = MFUploader.getUnits(oData.response.resumable_upload.bitmap, disableInstantUploads);
                        // Cap bitmap to units
                        oFile.units.length = iUnits;
                        // Increment the bytes the server already has
                        oFile.bytesUploaded = MFUploader.getBytesUploaded(oFile);
                    }
                }

                // Duplicate name, requires user action to continue
                // unless user action has been applied to all or global is set
                if (oData.response.file_exists === "yes") {

                    oFile.dupeQuickkey = oData.response.duplicate_quickkey;
                    oFile.state = oThis.FILE_STATE_DUPLICATE;
                    oThis._emit(oThis.EVENT_FILE_STATE, oFile, oFile.state, oFile.quickkey, oFile.dupeQuickkey);

                    // Duplicate action available, no need to confirm or queue
                    if (oThis._actionOnDuplicate) {
                        // Skip file
                        if (oThis._actionOnDuplicate === 'skip') {
                            // Update state
                            oFile.state = oThis.FILE_STATE_SKIPPED;
                            oFile.bytesUploaded = 0;
                            oFile.bytesHashed = 0;
                            oThis._emit(oThis.EVENT_FILE_STATE, oFile, oFile.state, oFile.quickkey, oFile.dupeQuickkey);
                        // Attempt to check upload again
                        } else {
                            // send to upload
                            oThis._doUpload(oFile, oThis._actionOnDuplicate);
                        }
                    // Awaiting confirmation
                    } else {
                        // Already awaiting a duplicate action, add to queue
                        if (oThis._awaitingDuplicateAction) {
                            oThis._duplicateConfirmQueue.push(oFile);
                        // Emit event, note we are awaiting a response
                        } else {
                            oThis._awaitingDuplicateAction = true;
                            oThis._emit(oThis.EVENT_DUPLICATE, oFile);
                        }
                    }

                // File is instant upload (hash already exists)
                } else if (oFile.uploadType === oThis.TYPE_INSTANT) {
                    // Update state
                    oFile.state = oThis.FILE_STATE_UPLOAD_CHECK;
                    oThis._emit(oThis.EVENT_FILE_STATE, oFile, oFile.state, oFile.quickkey, oFile.dupeQuickkey);
                    // Do instant upload
                    oThis._instant(oFile);

                // File is resumable upload
                } else if (oFile.uploadType === oThis.TYPE_RESUMABLE) {
                    // Force an upload if we disabled instant uploads,
                    // otherwise we skip straight to poll upload
                    if (disableInstantUploads || oData.response.resumable_upload.all_units_ready !== 'yes') {
                        // Update state
                        oFile.state = oThis.FILE_STATE_UPLOAD_CHECK;
                        oThis._emit(oThis.EVENT_FILE_STATE, oFile, oFile.state, oFile.quickkey, oFile.dupeQuickkey);
                        oThis._resumable(oFile);
                    } else {
                        // Update state
                        oFile.bytesUploaded = oFile.size;
                        oThis._emit(oThis.EVENT_UPLOAD_PROGRESS, oFile, oFile.bytesUploaded);
                        oFile.state = oThis.FILE_STATE_VERIFYING;
                        oThis._emit(oThis.EVENT_FILE_STATE, oFile, oFile.state, oFile.quickkey, oFile.dupeQuickkey);
                        oFile.uploadKey = oData.response.resumable_upload.upload_key;
                        oThis._pollUpload(oFile);
                    }

                // Error
                } else {
                    var serverError = oThis._getFailureError(oData, 'Invalid check response.');
                    oThis._report(serverError, oFile);
                    oFile.state = oThis.FILE_STATE_FAILED;
                    oThis._emit(oThis.EVENT_FILE_STATE, oFile, oFile.state, oFile.quickkey, oFile.dupeQuickkey, serverError);
                }
            },
            error: function(e) {
                var networkError = oThis._getNetworkError(e, 'No check response.');
                oThis._report(networkError, oFile);
                oFile.state = oThis.FILE_STATE_FAILED;
                oThis._emit(oThis.EVENT_FILE_STATE, oFile, oFile.state, oFile.quickkey, oFile.dupeQuickkey, networkError);
            }
        }));
    };

    MFUploader.prototype._doUpload = function(oFile, sDuplicateAction) {
        // Send to appropriate upload method
        if (oFile.uploadType === this.TYPE_INSTANT) {
            this._instant(oFile, sDuplicateAction);
        } else {
            this._resumable(oFile, sDuplicateAction);
        }
    };

    MFUploader.prototype._parseResponse = function(oResponse) {
        var oData;
        try {
            oData = JSON.parse(oResponse);
        } catch (e) {
            return {valid: false, data: e};
        }

        return {valid: true, data: oData};
    };

    MFUploader.prototype._getNetworkError = function(eProgressError, sDefault) {
        var sMessage = eProgressError.message || eProgressError.name;
        if (!sMessage) sMessage = sDefault;
        return 'Network error: ' + sMessage;
    };

    MFUploader.prototype._getFailureError = function(oErrorData, sDefault) {
        if (typeof oErrorData === 'string') {
            return oErrorData;
        }

        if (oErrorData instanceof XMLHttpRequest) {
            var oResponse = this._parseResponse(oErrorData.responseText);
            var oData = oResponse.data;
            if (oResponse.valid && oData) {
                return this._getFailureErrorTextFromData(oData, sDefault);
            } else {
                return sDefault + ' (Invalid response)';
            }
        }

        if (typeof oErrorData == "object") {
            if (typeof oErrorData.data == "object") {
                return this._getFailureErrorTextFromData(oErrorData.data, sDefault);
            }
            if (typeof oErrorData.response == "object") {
                return this._getFailureErrorTextFromData(oErrorData, sDefault);
            }
        }

        return sDefault;
    };

    MFUploader.prototype._getFailureErrorTextFromData = function(oData, sDefault) {
        if ('response' in oData) {
            if ('message' in oData.response
                && typeof oData.response.message == 'string'
               ) {
                return oData.response.message;
            } else {
                return sDefault;
            }
        }
    }

    MFUploader.prototype._resumable = function(oFile, sDuplicateAction) {
        if (oFile.state === this.FILE_STATE_VERIFYING)
            return true;
        if (oFile.state === this.FILE_STATE_COMPLETE)
            return true;

        var oThis = this;
        var bUnitsRemaining = false;
        oFile.units && oFile.units.forEach(function(bUploaded, iUnit) {
            if (!bUploaded) {
                oThis._uploadQueue.push([oFile, iUnit, sDuplicateAction]);
                bUnitsRemaining = true;
            }
        });

        oFile.state = this.FILE_STATE_UPLOAD_QUEUED;
        this._emit(this.EVENT_FILE_STATE, oFile, oFile.state, oFile.quickkey, oFile.dupeQuickkey);

        // Try to upload queued units
        this._nextUploads();

        return bUnitsRemaining;
    };

    MFUploader.prototype._instant = function(oFile, sDuplicateAction) {
        var oThis = this;
        var oParams = {
            hash: oFile.hashes.full,
            size: oFile.size,
            filename: MFUploader.sanitizeFilename(oFile.name)
        };

        // Set subfolder key if specified
        if (!this._filedropKey && this._options.folderkey)
            oParams.folder_key = this._options.folderkey;

        // Relative path specified
        if (oFile.path)
            oParams.path = oFile.path;

        // Duplicate action is global or specified explicitly
        if (sDuplicateAction || this._actionOnDuplicate)
            oParams.action_on_duplicate = (sDuplicateAction || this._actionOnDuplicate);

        oFile.XHRs.push(this._apiRequest('instant', oParams, {
            load: function(evt) {
                var oResponse = oThis._parseResponse(this.responseText);
                var oData = oResponse.data;
                if (!oData || !oResponse.valid) {
                    // Retry in 1 second
                    setTimeout(function() { oThis._instant(oFile, oParams.action_on_duplicate); }, 1000);
                    oThis._log('Instant response invalid.', evt);
                    return;
                }

                // File has not changed
                var sQuickkey = oData.response && oData.response.quickkey;
                if (oData.response && oData.response.error === 238) {
                    sQuickkey = oFile.dupeQuickkey;
                // File contains virus
                } else if (oData.response && oData.response.error === 295) {
                    oFile.virusFlagged = true;
                }

                // Instant upload, no duplicate
                if (sQuickkey) {
                    oFile.quickkey = sQuickkey;
                    oFile.bytesUploaded = oFile.size;
                    oFile.state = oThis.FILE_STATE_COMPLETE;
                    oThis._emit(oThis.EVENT_UPLOAD_PROGRESS, oFile, oFile.bytesUploaded);
                    oThis._emit(oThis.EVENT_FILE_STATE, oFile, oFile.state, oFile.quickkey, oFile.dupeQuickkey);
                // Quickkey missing
                } else {
                    var serverError = oThis._getFailureError(oData, 'Invalid instant response.');
                    oThis._report(serverError, oFile);
                    oFile.state = oThis.FILE_STATE_FAILED;
                    oThis._emit(oThis.EVENT_FILE_STATE, oFile, oFile.state, oFile.quickkey, oFile.dupeQuickkey, serverError);
                }
            },
            error: function(e) {
                var networkError = oThis._getNetworkError(e, 'No instant response.');
                oThis._report(networkError, oFile);
                oFile.state = oThis.FILE_STATE_FAILED;
                oThis._emit(oThis.EVENT_FILE_STATE, oFile, oFile.state, oFile.quickkey, oFile.dupeQuickkey, networkError);
            }
        }));
    };

    MFUploader.prototype._pollUpload = function(oFile) {
        var oThis = this;
        var oParams = {
            key: oFile.uploadKey,
            resumable: 'yes'
        };

        // Clear units in queue
        this.removeQueuedFile(oFile);

        oFile.XHRs.push(this._apiRequest('poll_upload', oParams, {
            load: function(evt) {
                var oResponse = oThis._parseResponse(this.responseText);
                var oData = oResponse.data;
                if (!oData || !oResponse.valid || !oData.response.doupload) {
                    // Retry in 1 second
                    setTimeout(function() { oThis._pollUpload(oFile); }, 1000);
                    oThis._log('Poll response invalid.', evt);
                    return;
                }

                var bSuccess = oData.response.doupload.result === '0';
                var iError = parseInt(oData.response.doupload.fileerror, 10);
                var iStatus = parseInt(oData.response.doupload.status, 10);

                // Save file error state
                if (iError === 5) {
                    oFile.virusFlagged = true;
                } else if (iError === 15) {
                    oFile.exceededStorage = true;
                    oFile.state = oThis.FILE_STATE_FAILED;
                } else if (0 < iError) {
                    oFile.state = oThis.FILE_STATE_FAILED;
                }

                // Units missing, restart resumable upload
                if (iStatus === 17) {
                    oFile.state = oThis.FILE_STATE_UPLOAD_CHECK;
                    oThis._emit(oThis.EVENT_FILE_STATE, oFile, oFile.state, oFile.quickkey, oFile.dupeQuickkey);
                    oThis._resumable(oFile);

                // All units uploaded
                } else if (bSuccess && (iStatus === 99 || iStatus === 98)) {
                    if (oFile.state == oThis.FILE_STATE_FAILED && 0 < iError) {
                        var errorMessage;
                        if (oFile.exceededStorage) {
                            errorMessage = 'File exceeded storage limit.';
                        } else {
                            errorMessage = 'Upload Failure (' + iError + ')';
                        }
                        oThis._report(errorMessage, oFile);
                        oThis._emit(oThis.EVENT_FILE_STATE, oFile, oFile.state, oFile.quickkey, oFile.dupeQuickkey, errorMessage);
                    } else {
                        // Quickkey returned, set if not already
                        if (!oFile.quickkey) {
                            oFile.quickkey = oData.response.doupload.quickkey;
                        }

                        // Quickkey known, status 99, consider upload successful
                        if (oFile.quickkey) {
                            oFile.bytesUploaded = oFile.size;
                            oThis._emit(oThis.EVENT_UPLOAD_PROGRESS, oFile, oFile.bytesUploaded);
                            oFile.state = oThis.FILE_STATE_COMPLETE;
                            oThis._emit(oThis.EVENT_FILE_STATE, oFile, oFile.state, oFile.quickkey, oFile.dupeQuickkey);
                        // Error, status 99, no quickkey known, fail!
                        } else {
                            var serverError = oThis._getFailureError(oData, 'Invalid poll response.');
                            oThis._report(serverError, oFile);
                            oFile.state = oThis.FILE_STATE_FAILED;
                            oThis._emit(oThis.EVENT_FILE_STATE, oFile, oFile.state, oFile.quickkey, oFile.dupeQuickkey, serverError);
                        }
                    }

                // Unfinished, continue to poll (3s)
                } else {
                    setTimeout(function() { oThis._pollUpload(oFile); }, 3000);
                }
            },
            error: function(e) {
                var networkError = oThis._getNetworkError(e, 'No poll response.');
                oThis._report(networkError, oFile);
                oFile.state = oThis.FILE_STATE_FAILED;
                oThis._emit(oThis.EVENT_FILE_STATE, oFile, oFile.state, oFile.quickkey, oFile.dupeQuickkey, networkError);
            }
        }));
    };

    MFUploader.prototype._passesFilter = function(oFile) {
        // If there is no filter, pass
        if (!this._options.filterByExtension) {
            return true;
        }

        var sExt = oFile.name.split('.').pop().toLowerCase(), // get extension
            aFilters = this._options.filterByExtension; // for storing extenstions from filter

        // Cast delimited string to Array
        if (!(aFilters instanceof Array)) {
            aFilters = aFilters.split(/[\s,]+/); // convert to array
        }

        for(var i=0, l=aFilters.length; i<l; i++) {
            if (aFilters[i].toLowerCase() === sExt) {
                return true;
            }
        }

        return false;
    };

    MFUploader.prototype.add = function(oFile) {
        // Prevent filtered files
        if (!this._passesFilter(oFile)) {
            return;
        }

        // Sort files by size
        MFUploader.sortFiles(this.files);

        var oThis = this;
        this._augmentFile(oFile);
        this.files.push(oFile);

        // File already flagged as exceeding file size limit
        if (oFile.exceededSizeLimit) {
            var errorMessage = 'File exceeded file size limit.';
            oThis._report(errorMessage, oFile);
            oFile.state = oThis.FILE_STATE_FAILED;
            oThis._emit(oThis.EVENT_FILE_STATE, oFile, oFile.state, oFile.quickkey, oFile.dupeQuickkey, errorMessage);
            return;
        }

        // this.eta();

        // Get thumbnail if configured to do so
        if (!!this._options.returnThumbnails
            && oFile.size < this.THUMB_SIZE_LIMIT
            && MFUploader.isImage(oFile.type)) {
            window.URL = window.URL || window.webkitURL;
            if (window.URL) {
                oFile.dataURL = window.URL.createObjectURL(oFile);
                oThis._emit(oThis.EVENT_FILE_STATE, oFile, oFile.state, oFile.quickkey, oFile.dupeQuickkey);
            }
        }

        // File already hashed, skip hashing and start upload check
        if (oFile.hashes && oFile.hashes.full) {
            oThis._uploadCheck(oFile);
            return;
        }

        // Add file to queue to be sent to the hasher
        // Note: the hasher has it's own internal queue
        this._hasherQueue.push([oFile, oFile.unitSize, {
            success: function(oHashes) {
                // Save hashes
                oFile.hashes = oHashes;
                // Send last progress event
                oFile.bytesHashed = oFile.size;
                oThis._emit(oThis.EVENT_HASH_PROGRESS, oFile, oFile.bytesHashed);
                // Update state
                oFile.state = oThis.FILE_STATE_HASHED;
                oThis._emit(oThis.EVENT_FILE_STATE, oFile, oFile.state, oFile.quickkey, oFile.dupeQuickkey);
                // Uploads already began
                if (oThis._uploaderStarted || oThis._uploadOnAdd) {
                    oThis._uploadCheck(oFile);
                // Still waiting for user to initiate upload
                } else {
                    oThis._waitingToStartUpload.push(oFile);
                }
            },
            progress: function(iBytesHashed) {
                // Update state
                if (oFile.state !== oThis.FILE_STATE_HASHING) {
                    oFile.state = oThis.FILE_STATE_HASHING;
                    oThis._emit(oThis.EVENT_FILE_STATE, oFile, oFile.state, oFile.quickkey, oFile.dupeQuickkey);
                }
                oFile.bytesHashed = iBytesHashed;
                oThis._emit(oThis.EVENT_HASH_PROGRESS, oFile, iBytesHashed);
            }
        }]);
        this._syncHasher();
    };

    MFUploader.prototype.startUpload = function() {
        this._uploaderStarted = true;
        this._waitingToStartUpload.forEach(this._uploadCheck, this);
        this._waitingToStartUpload = [];
    };

    MFUploader.prototype.send = function(aFiles) {
        // Transform FileList into an Array
        aFiles = [].slice.call(aFiles);

        // Optionally add files before sending
        if (aFiles && aFiles.length > 0) {
            if (aFiles.length === 1) {
                this.add(aFiles[0]);
            } else {
                // Add all files
                for(var i=0, l=aFiles.length; i<l; i++) {
                    this.add(aFiles[i]);
                }
            }
        }
    };

    MFUploader.prototype.duplicateAction = function(oFile, sAction, bApplyAll) {
        var aChoices = ['keep', 'skip', 'replace'];
        // Validate duplicate action and valid action
        if (oFile.state === this.FILE_STATE_DUPLICATE && aChoices.indexOf(sAction) !== -1) {

            // No longer awaiting user confirmation
            this._awaitingDuplicateAction = false;

            // User chose to skip
            if (sAction === 'skip') {
                // Update state
                oFile.state = this.FILE_STATE_SKIPPED;
                this._emit(this.EVENT_FILE_STATE, oFile, oFile.state, oFile.quickkey, oFile.dupeQuickkey);
            // Send to upload
            } else {
                this._doUpload(oFile, sAction);
            }

            // Apply choice for future occurrences in this uploader instance
            // as well as any in the duplicate queue
            if (bApplyAll) {
                this._actionOnDuplicate = sAction;
                this._duplicateConfirmQueue.forEach(function(oQueuedFile) {
                    // Confirm they are in a duplicate state
                    if (oQueuedFile.state === this.FILE_STATE_DUPLICATE) {
                        // Skip all in queue
                        if (sAction === 'skip') {
                            // Update state
                            oQueuedFile.state = this.FILE_STATE_SKIPPED;
                            this._emit(this.EVENT_FILE_STATE, oQueuedFile, oFile.state, oFile.quickkey, oFile.dupeQuickkey);
                        // Upload all in queue
                        } else {
                            this._doUpload(oQueuedFile, sAction);
                        }
                    }
                }, this);
                // Clear queue
                this._duplicateConfirmQueue = [];
            // No global action, emit event for next user confirmation if available
            } else if (this._duplicateConfirmQueue.length > 0) {
                this._emit(this.EVENT_DUPLICATE, this._duplicateConfirmQueue.shift());
            }
        }
    };

    MFUploader.prototype.removeQueuedFile = function(oFile) {
        // Remove from pending upload queue
        this._uploadQueue = this._uploadQueue.filter(function(aItem) {
            return aItem[0] !== oFile;
        });
    };

    MFUploader.prototype.cancelFile = function(oFile) {
        if (oFile.state !== this.FILE_STATE_COMPLETE && oFile.state !== this.FILE_STATE_VERIFYING) {
            // Cancel hashing
            if (this._hasher) this._hasher.cancelFile(oFile);

            // Remove from pending upload queue
            this.removeQueuedFile(oFile);

            // Remove from start queue
            var startQueueIndex = this._waitingToStartUpload.indexOf(oFile);
            if (startQueueIndex !== -1) {
                this._waitingToStartUpload.splice(startQueueIndex, 1);
            }

            // Abort active connections
            if (oFile.XHRs) {
                oFile.XHRs.forEach(function(oXHR) {
                    if (oXHR && oXHR.abort) {
                        oXHR.onreadystatechange = null;
                        oXHR.abort();
                    }
                });
            }

            // This was an active upload
            if (oFile.state === this.FILE_STATE_UPLOADING) {
                // Decrement active upload count
                this._activeUploads--;

                // Upload next file if any in queue
                this._nextUploads();
            }

            // Send abort state event
            oFile.state = this.FILE_STATE_ABORTED;
            this._emit(this.EVENT_FILE_STATE, oFile);
        }
    };

    MFUploader.prototype.cancel = function() {
        var oThis = this;
        this.files.forEach(function(oFile) {
            oThis.cancelFile(oFile);
        });

        if (this._hasher) this._hasher.cancel();
        this._hasherQueue = [];
        this._uploadQueue = [];
        this._waitingToStartUpload = [];
        this._duplicateConfirmQueue = [];
        this._aXHRs.forEach(function(oXHR) {
            try {
                oXHR.onreadystatechange = null;
                oXHR.abort();
            } catch(e) {}
        });
        this._aXHRs = [];
        this.files = [];
        this._uploaderStarted = false;
        this._activeUploads = 0;
        this.progress = null;
    };

    MFUploader.prototype.eta = function() {
        var oThis = this;
        var progress = this.progress;
        if (!progress) {
            progress = this.progress = {
                uploaded: 0,
                remaining: 0,
                lastCheck: 0,
                transferSpeed: 0,
                etaZero: false,
                startTime: Date.now()
            };
        }

        var iNow = Date.now();
        var iDuration = (iNow - progress.startTime) / 1000;
        var iSecondsRemaining = 0;
        var iBytesTotal = 0;
        var iBytesUploaded = 0;
        oThis.files.forEach(function(file) {
            iBytesTotal += file.size;
            iBytesUploaded += file.state !== oThis.FILE_STATE_COMPLETE
                && file.state !== oThis.FILE_STATE_SKIPPED
                && file.state !== oThis.FILE_STATE_FAILED
                ? file.bytesUploaded
                : file.size;
        });

        // No progress since last poll
        // decrement last calc by poll rate (1s)
        if (iBytesUploaded === progress.uploaded && progress.remaining > 0) {
            iSecondsRemaining = progress.remaining - ((iNow - progress.lastCheck) / 1000);
        // Calculate transfer speed
        } else {
            // Transfer speed within the update window
            var iTransferSpeed = iDuration && iBytesUploaded ? iBytesUploaded / iDuration : 0;
            progress.transferSpeed = iTransferSpeed;
            iSecondsRemaining = (iBytesTotal - iBytesUploaded) / iTransferSpeed;
        }

        progress.uploaded = iBytesUploaded;
        progress.remaining = iSecondsRemaining;
        progress.lastCheck = iNow;

        // Invalid ETA
        if (!isFinite(iSecondsRemaining)) {
            return 'N/A';
        }

        // Hide "soon" message because the upload has more than a minute left
        if (progress.etaZero && iSecondsRemaining > 60) {
            progress.etaZero = false;
        }

        // Estimated 0 seconds left, show "soon" message
        if (iSecondsRemaining <= 5) {
            progress.etaZero = true;
        }

        var sEtaString = '';
        var iHours = Math.max(0, Math.floor(iSecondsRemaining / 3600) % 24);
        var iMinutes = Math.max(0, Math.floor(iSecondsRemaining / 60) % 60);
        var iSeconds = Math.max(0, Math.floor(iSecondsRemaining % 60));

        // Too short to estimate
        if (progress.etaZero) {
            sEtaString = 'A few seconds';

        // Too long to estimate
        } else if (iHours >= 24) {
            sEtaString = 'A day or more';

        // No more time left to estimate
        } else {
            if (iHours > 0)
                sEtaString += (iHours + ' hour') + (iHours > 1 ? 's, ' : ', ');
            if (iMinutes > 0)
                sEtaString += iMinutes + ' min, ';
            sEtaString += iSeconds + ' sec';
        }

        return {seconds: iSecondsRemaining, message: sEtaString + ' remaining…'};
    };

    MFUploader.prototype.etaDescription = function() {
        var sEtaString = '';
        var progress = this.progress;
        if (progress.etaZero) {
            sEtaString = '00:00:00';
        } else if (progress.remaining / 3600 < 24) {
            var sHours = ('0' + Math.max(0, Math.floor(progress.remaining / 3600) % 24)).slice(-2);
            var sMinutes = ('0' + Math.max(0, Math.floor(progress.remaining / 60) % 60)).slice(-2);
            var sSeconds = ('0' + Math.max(0, Math.floor(progress.remaining % 60))).slice(-2);
            sEtaString = sHours + ':' + sMinutes + ':' + sSeconds + ' | ';
        }

        return sEtaString
            + MFUploader.getNearestUnitSize(progress.transferSpeed) + '/s | '
            + MFUploader.getNearestUnitSize(progress.uploaded);
    };

    if (typeof module !== 'undefined') {
        module.exports = MFUploader;
    }

    if (typeof window !== 'undefined') {
        window.MFUploader = MFUploader;
        if (window.mfUploaderReady && !window.mfUploaderReady.init) {
            window.mfUploaderReady.init = true;
            window.mfUploaderReady();
        }
    }
})();
