/*******************************************************************************
 * @name AppRootView
 * @author MediaFire <cavitt.glover@mediafire.com>
 * @description Top-level UI for the entire app.
 ******************************************************************************/

import * as assert from 'assert';
import * as _ from 'lodash';
import * as RX from 'reactxp';
import Navigator, {Types as NavTypes} from 'reactxp-navigation';
import {ComponentBase} from 'resub';
import {Colors} from './AppStyles';
import NavStore from '../nav/NavStore';
import * as NavModels from '../nav/NavModels';
import UploaderPanel from '../uploader/UploaderPanel';

interface AppRootViewProps extends RX.CommonProps {
  onLayout?: (e: RX.Types.ViewOnLayoutEvent) => void;
}

interface AppRootViewState {
  viewTitle: string;
  navContext: NavModels.RootNavContext;
}

const _styles = {
  root: RX.Styles.createViewStyle({
    flex: 1,
    alignSelf: 'stretch'
  }),
  stackViewBackground: RX.Styles.createViewStyle({
    flex: 1,
    alignSelf: 'stretch',
    backgroundColor: Colors.white
  })
};

export default class AppRootView extends ComponentBase<any/*AppRootViewProps*/, AppRootViewState> {
  private _navigator: Navigator | null = null;

  protected _buildState(props: AppRootViewProps, initState: boolean): Partial<AppRootViewState> | undefined {
    let newNavContext = NavStore.getNavContext();

    let partialState: Partial<AppRootViewState> = {
      viewTitle: this._getViewTitle(newNavContext),
      navContext: newNavContext
    };

    if (this._navigator) {
      let newNavStack = newNavContext as NavModels.StackRootNavContext;
      let mustResetRouteStack = true;

      if (this.state.navContext && this.state.navContext.isStackNav) {
        let prevNavStack = this.state.navContext as NavModels.StackRootNavContext;

        if (newNavStack.stack.length === prevNavStack.stack.length + 1) {
          if (this._compareNavStack(newNavStack.stack, prevNavStack.stack, prevNavStack.stack.length)) {
            this._navigator.push(this._createNavigatorRoute(newNavStack.stack[newNavStack.stack.length - 1].viewId));
            mustResetRouteStack = false;
          }
        } else if (newNavStack.stack.length === prevNavStack.stack.length - 1) {
          if (this._compareNavStack(newNavStack.stack, prevNavStack.stack, newNavStack.stack.length)) {
            this._navigator.pop();
            mustResetRouteStack = false;
          }
        }
      }

      if (mustResetRouteStack) {
        this._navigator.immediatelyResetRouteStack(this._createNavigatorRouteStack(newNavStack));
      }
    }

    return partialState;
  }

  render(): JSX.Element | null {
    return (
      <RX.View style={_styles.root} onLayout={this.props.onLayout}>
        <Navigator ref={this._onMountNavigator} renderScene={this._onRenderScene} />
      </RX.View>
    );
  }

  /*private _showBackButton(viewId: NavModels.NavViewId): boolean {
    return viewId !== NavModels.NavViewId.UploaderComposite &&
      viewId !== NavModels.NavViewId.Uploader;
  }*/

  private _getViewTitle(navContext: NavModels.RootNavContext): string {
    if (navContext.isStackNav) {
      let stackContext = navContext as NavModels.StackRootNavContext;
      let topViewId = stackContext.stack[stackContext.stack.length - 1].viewId;

      switch (topViewId) {
        case NavModels.NavViewId.Uploader:
          return 'Select Files to Upload';
        case NavModels.NavViewId.NewUpload:
          return 'New Upload';
        case NavModels.NavViewId.ViewUpload:
          return 'View Upload';
        default:
          assert.fail('Unknown view');
          return '';
      }
    } else {
      return '';
    }
  }

  private _onMountNavigator = (elem: any) => {
    this._navigator = elem;

    if (this._navigator) {
      this._navigator.immediatelyResetRouteStack(
        this._createNavigatorRouteStack(
          this.state.navContext as NavModels.StackRootNavContext
        )
      );
    }
  }

  private _onRenderScene = (navigatorRoute: NavTypes.NavigatorRoute): JSX.Element | undefined => {
    const viewId = navigatorRoute.routeId as NavModels.NavViewId;
    //const showBackButton = this._showBackButton(viewId);

    return (
      <RX.View style={ _styles.stackViewBackground }>
        {/*<AppTopBarStack
          title={ this.state.viewTitle }
          showBackButton={ showBackButton }
          onBack={ this._onBack }
        />*/}
        { this._renderSceneContents(viewId) }
      </RX.View>
    );
  }

  private _renderSceneContents(viewId: NavModels.NavViewId) {
    switch (viewId) {
      case NavModels.NavViewId.Uploader:
        return (
          <UploaderPanel
            onSelect={ this._onSelectUploadFromList }
            onCreateNew={ this._onCreateNewUpload }
          />
        );

      default:
        return undefined;
    }
  }

  private _onSelectUploadFromList = (selectedId: string) => {
    NavStore.navigateToUploader(selectedId, false);
  }

  private _onCreateNewUpload = () => {
    NavStore.navigateToUploader(undefined, true);
  }

  /*private _onBack = () => {
    if (this.state.navContext.isStackNav) {
      NavStore.popNavigationStack();
    }
  }*/

  private _createNavigatorRouteStack(stackContext: NavModels.StackRootNavContext): NavTypes.NavigatorRoute[] {
    return _.map(stackContext.stack, (viewContext, index) => {
      return this._createNavigatorRoute(viewContext.viewId);
    });
  }

  private _createNavigatorRoute(viewId: NavModels.NavViewId): NavTypes.NavigatorRoute {
    return {
      routeId: viewId,
      sceneConfigType: NavTypes.NavigatorSceneConfigType.FloatFromRight
    };
  }

  private _compareNavStack(stackA: NavModels.ViewNavContext[], stackB: NavModels.ViewNavContext[], count: number): boolean {
    for (let i = 0; i < count; i++) {
      if (stackA[i].viewId !== stackB[i].viewId) {
        return false;
      }
    }

    return true;
  }
}
