/*
* NavLinkConverter.tsx
*
* Converts between app (deep-link) URLs and navigation contexts.
*/

import * as assert from 'assert';
import * as _ from 'lodash';

import NavActions from './NavActions';
import * as NavModels from './NavModels';

import AppConfig from '../app/AppConfig';

export default class NavLinkConverter {
  static getUrlFromContext(context: NavModels.RootNavContext): string {
    let url = AppConfig.getFrontendBaseUrl();

    if (context.isStackNav) {
      const stackContext = context as NavModels.StackRootNavContext;
      const topViewContext = stackContext.stack[stackContext.stack.length - 1];

      if (topViewContext instanceof NavModels.UploaderViewNavContext) {
        url += '/uploads';
        return url;
      } else if (topViewContext instanceof NavModels.ViewUploadViewNavContext) {
        url += '/uploads?selected=' + encodeURIComponent(topViewContext.uploadId);
        return url;
      } else if (topViewContext instanceof NavModels.NewUploadViewNavContext) {
        url += '/uploads?selected=new';
        return url;
      }
    } else {
      let compositeContext = context as NavModels.CompositeRootNavContext;
      if (compositeContext instanceof NavModels.UploadRootNavContext) {
        url += '/uploads';
        let uploaderContext = context as NavModels.UploadRootNavContext;
        if (uploaderContext.showNewUploaderPanel) {
          url += '?selected=new';
        } else if (uploaderContext.uploader.selectedUploadId) {
          url += '?selected=' + encodeURIComponent(uploaderContext.uploader.selectedUploadId);
        }
        return url;
      } else {
        // TODO - need to implement
        assert.fail('Unimplemented');
      }
    }

    return '';
  }

  static getContextFromUrl(url: string, isStackNav: boolean): NavModels.RootNavContext | undefined {
    let urlObj;
    try { urlObj = new URL(url); } catch(e) {}
    if (!urlObj) return undefined;

    let pathElements = _.map(_.split(urlObj.pathname, '/'), elem => decodeURIComponent(elem));
    if (pathElements.length < 2) {
      return undefined;
    }

    switch (pathElements[1]) {
      case 'uploads':
        let selectedUploadId: string | undefined;
        let showNewPanel = false;

        let selectedValue = urlObj.searchParams.get('selected');
        if (selectedValue === 'new') {
          showNewPanel = true;
        } else if (selectedValue) {
          selectedUploadId = selectedValue;
        }

        return NavActions.createUploaderContext(isStackNav, selectedUploadId, showNewPanel);

      default:
        return undefined;
    }
  }
}
