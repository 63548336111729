/**
* NavModels.ts
*
* Interface and enumeration definitions used for app navigation.
* A "navigation context" describes a location within the app and
* any associated state that may be expressed within a deep link.
*
* A "view nav context" describes the state of a view.
*
* A "root nav context" describes the nav context for the app's
* root view - the top of the visual hierarchy. Depending on the
* screen size, the root nav context may be stack-based (consisting
* of a stack of individual panels) or composite (in which multiple
* views are displayed side by side).
*/

import * as _ from 'lodash';

export enum NavViewId {
  UploaderComposite = 1,
  Uploader,
  NewUpload,
  ViewUpload
}

//----------------------------------------
// Root nav contexts
//----------------------------------------
export abstract class RootNavContext {
  constructor(public isStackNav: boolean) {
  }

  abstract clone(): RootNavContext;
}

export abstract class CompositeRootNavContext extends RootNavContext {
  constructor(public viewId: NavViewId) {
    super(false);
  }
}

export class StackRootNavContext extends RootNavContext {
  stack: ViewNavContext[];

  constructor() {
    super(true);
    this.stack = [];
  }

  clone(): StackRootNavContext {
    let clone = new StackRootNavContext();
    _.each(this.stack, navContext => {
      clone.stack.push(navContext.clone());
    });
    return clone;
  }
}

export class UploadRootNavContext extends CompositeRootNavContext {
  uploader: UploaderViewNavContext;

  constructor(selectedUploadId?: string, public showNewUploaderPanel = false) {
    super(NavViewId.UploaderComposite);
    this.uploader = new UploaderViewNavContext(selectedUploadId);
  }

  clone(): UploadRootNavContext {
    return new UploadRootNavContext(this.uploader.selectedUploadId, this.showNewUploaderPanel);
  }
}

//----------------------------------------
// View nav contexts
//----------------------------------------

export abstract class ViewNavContext {
  constructor(public viewId: NavViewId) {
  }

  abstract clone(): ViewNavContext;
}

export class UploaderViewNavContext extends ViewNavContext {
  constructor(public selectedUploadId?: string) {
    super(NavViewId.Uploader);
  }

  clone(): UploaderViewNavContext {
    return new UploaderViewNavContext(this.selectedUploadId);
  }
}

export class NewUploadViewNavContext extends ViewNavContext {
  constructor() {
    super(NavViewId.NewUpload);
  }

  clone(): NewUploadViewNavContext {
    return new NewUploadViewNavContext();
  }
}

export class ViewUploadViewNavContext extends ViewNavContext {
  constructor(public uploadId: string) {
    super(NavViewId.ViewUpload);
  }

  clone(): ViewUploadViewNavContext {
    return new ViewUploadViewNavContext(this.uploadId);
  }
}
