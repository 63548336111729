/**
* Content.ts
*
* Utilities for folder and file content
*/

import * as RX from 'reactxp';
import {UploadFileStates} from '../views/uploader/UploaderModels';

export const QUICKKEY_LEGACY_LENGTH = 11;
export const QUICKKEY_LENGTH = 15;
export const FOLDERKEY_LENGTH = 13;

export function isValidQuickkey(key: string) {
  if (!key) return false;
  if (key.length !== QUICKKEY_LENGTH && key.length !== QUICKKEY_LEGACY_LENGTH)
    return false;
  if (/[^a-zA-Z0-9]/.test(key)) return false;
  return true;
}

export function isValidFolderkey(key: string) {
  if (!key) return false;
  if (key === 'myfiles') return true;
  if (key.length !== FOLDERKEY_LENGTH) return false;
  if (/[^a-zA-Z0-9]/.test(key)) return false;
  return true;
}

export function getLink(key: string, name: string = '', type: string = '') {
  if (isValidFolderkey(key)) {
    return 'https://www.mediafire.com/folder/'
    + (key + '/')
    + (name ? escape(name).replace(/%20/g, '_') : '');
  }

  return 'https://www.mediafire.com/'
    + (type && type.substr(0,6) === 'image/' ? 'view/' : 'file/')
    + (key + '/')
    + (name ? escape(name).replace(/%20/g, '_') : '')
    + '/file';
}

export function copyLink(key: string, name: string = '', type: string = '') {
  // Copy share URL to clipboard
  RX.Clipboard.setText(getLink(key, name, type));

  // Unicorn: show notification
  try {
    parent.postMessage('mf-uploader-copy-link', '*'); 
  } catch(e) {}

  // Legacy: set links to public
  try {
    const notify = parent['Zi'] ? parent['Zi'] : parent['ShowStatusMessage'];
    if (notify) notify(0, "Copied share link to clipboard!");
    if (isValidFolderkey(key)) {
      parent['ValidateFolderCopyLinkSafe'](key);
      const mediafire = parent['tH'] ? parent['tH'] : parent['MEDIAFIRE'];
      const api = mediafire['yJ'] ? mediafire['yJ'] : mediafire['MFAPI_Folder'];
      const folder = new api(key);
      const action = folder['aax'] ? folder['aax'] : folder['MFAPI_MakePublic'];
      action && action();
    } else {
      const mediafire = parent['tH'] ? parent['tH'] : parent['MEDIAFIRE'];
      const api = mediafire['Cp'] ? mediafire['Cp'] : mediafire['MFAPI_File'];
      const file = new api(key);
      const action = file['aax'] ? file['aax'] : file['MFAPI_MakePublic'];
      action && action(function() {
        const cache = mediafire.files.memory;
        if (cache[key]) cache[key].privacy = 'public';
      });
    }
  } catch (e) {}
}

export function shareLink(quickkey, state) {
  // Unicorn: open share dialog
  try {
    parent.postMessage(JSON.stringify({
      event: 'mf-upload-share',
      preemptive: state !== UploadFileStates.Finished,
      id: quickkey
    }), '*');
  } catch(e) {}

  // Legacy: open share dialog
  try {
    const legacyShare = parent['R'] || parent['GLB_fDisplayShareBox'];
    if (!legacyShare) return;
    legacyShare({files: [quickkey]}, state !== UploadFileStates.Finished);
  } catch(e) {}
}
