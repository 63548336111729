/*******************************************************************************
 * @name AppMainWeb
 * @author MediaFire <cavitt.glover@mediafire.com>
 * @description Main entry point for the web app.
 ******************************************************************************/

// Then shimming...
import * as ShimHelpers from '../../utilities/ShimHelpers';
ShimHelpers.shimEnvironment(__DEV__, false);

// Configuration...
import AppConfig from './AppConfig';
AppConfig.initialize();

import {DbProvider} from 'nosqlprovider';
import {InMemoryProvider} from 'nosqlprovider/dist/InMemoryProvider';
//import {IndexedDbProvider} from 'nosqlprovider/dist/IndexedDbProvider';
//import {WebSqlProvider} from 'nosqlprovider/dist/WebSqlProvider';
import * as SyncTasks from 'synctasks';
import AppMain from './AppMain';

class AppMainWeb extends AppMain {
  protected _getDbProvidersToTry(): DbProvider[] {
    return [
      //new IndexedDbProvider(),
      //new WebSqlProvider(),
      new InMemoryProvider()
    ];
  }

  protected _getInitialUrl(): SyncTasks.Promise<string | undefined> {
    return SyncTasks.Resolved(window.location.href);
  }
}

export default new AppMainWeb();
