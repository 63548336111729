/******************************************************************************
 * @name Dialog
 * @author MediaFire <cavitt.glover@mediafire.com>
 * @description Defines the contents (including a title and buttons) for
 * a dialog box. Typically embedded within an RX.Modal component.
*******************************************************************************/

import * as _ from 'lodash';
import * as RX from 'reactxp';
import * as SyncTasks from 'synctasks';
import {ComponentBase} from 'resub';
import ImageSource from 'modules/images';
import Modal from './Modal';
import Button from './Button';
import HoverButton from './HoverButton';
import {Colors, Fonts, FontSizes} from '../app/AppStyles';
import KeyCodes from '../../utilities/KeyCodes';

export interface DialogButton {
  text?: string;
  onPress?: () => void;
  buttonStyle?: RX.Types.ButtonStyleRuleSet;
  textStyle?: RX.Types.TextStyleRuleSet;
  isCancel?: boolean;
  isSubmit?: boolean;
  disabled?: boolean;
  primary?: boolean;
  secondary?: boolean;
  warn?: boolean;
  outline?: boolean;
}

export interface DialogProps {
  dialogId: string;
  prompt?: boolean;
  maxWidth?: number;
  maxHeight?: number;
  containerStyle?: RX.Types.ViewStyleRuleSet;
  title?: string;
  text?: string;
  children?: RX.Types.ReactNode;
  buttons?: DialogButton[];
}

const _modalPadding = 16;
const _styles = {
  container: RX.Styles.createViewStyle({
    flex: 1,
    backgroundColor: Colors.dialogBackground,
    borderWidth: 1,
    borderColor: Colors.dialogBorder
  }),
  titleText: RX.Styles.createTextStyle({
    flex: 1,
    textAlign: 'left',
    marginTop: _modalPadding,
    marginBottom: _modalPadding / 2,
    paddingHorizontal: _modalPadding * 1.5,
  }),
  contentText: RX.Styles.createTextStyle({
    font: Fonts.displayRegular,
    fontSize: FontSizes.size14,
    color: Colors.dialogText,
    textAlign: 'left',
    paddingVertical: _modalPadding
  }),
  contentContainer: RX.Styles.createViewStyle({
    flex: 1,
    paddingHorizontal: _modalPadding * 1.5
  }),
  buttonContainer: RX.Styles.createViewStyle({
    alignItems: 'stretch',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: _modalPadding
  }),
  button: RX.Styles.createViewStyle({
    marginLeft: 12
  }),
  panelContainer: RX.Styles.createViewStyle({
    flexDirection: 'row',
  }),
  panelHeader: RX.Styles.createTextStyle({
    font: Fonts.displayLight,
    fontSize: FontSizes.size20,
    color: Colors.greyTitle
  }),
  displayText: RX.Styles.createTextStyle({
    font: Fonts.displayRegular,
    fontSize: FontSizes.size16,
    color: Colors.gray66
  }),
  closeContainer: RX.Styles.createViewStyle({
    width: 40,
    margin: _modalPadding / 2,
  }),
  closeText: RX.Styles.createTextStyle({
    font: Fonts.displayRegular,
    fontSize: FontSizes.size16,
    color: Colors.menuText,
    margin: 8
  }),
  closeTextHover: RX.Styles.createTextStyle({
    color: Colors.menuTextHover
  }),
  closeImage: RX.Styles.createImageStyle({
    alignSelf: 'center',
    height: 24,
    width: 24,
    opacity: 0.5
  })
};

export default class Dialog extends ComponentBase<DialogProps, RX.Stateless> {
  componentDidMount() {
    super.componentDidMount();
    RX.Input.backButtonEvent.subscribe(this._onBack);
    RX.Input.keyUpEvent.subscribe(this._onKeyUp);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    RX.Input.backButtonEvent.unsubscribe(this._onBack);
    RX.Input.keyUpEvent.unsubscribe(this._onKeyUp);
  }

  render() {
    // Title Text
    let optionalTitleText: JSX.Element | undefined;
    if (this.props.title) {
      optionalTitleText = (
        <RX.View style={_styles.panelContainer} importantForAccessibility={RX.Types.ImportantForAccessibility.Yes}>
          <RX.Text
            style={[_styles.panelHeader, _styles.titleText]}
            importantForAccessibility={RX.Types.ImportantForAccessibility.NoHideDescendants}
          >
            {this.props.title}
          </RX.Text>
          <RX.View style={[_styles.closeContainer]}>
            {!this.props.prompt && <HoverButton onPress={this._onPressClose} onRenderChild={this._renderCloseText}/>}
          </RX.View>
        </RX.View>
      );
    }

    // Content (children)
    let optionalContent: RX.Types.ReactNode | undefined;
    if (this.props.children) {
      optionalContent = this.props.children;
    } else if (this.props.text) {
      optionalContent = (
        <RX.Text style={[_styles.displayText, _styles.contentText]}>
          {this.props.text}
        </RX.Text>
      );
    }

    // Buttons
    let optionalButtonsContainer: JSX.Element | undefined;
    if (this.props.buttons && this.props.buttons.length > 0) {
      const optionalButtons = _.map(this.props.buttons, this._renderButton);

      optionalButtonsContainer = (
        <RX.View style={_styles.buttonContainer}>
          {optionalButtons}
        </RX.View>
      );
    }

    return (
      <Modal
        modalId={this.props.dialogId}
        modalWidth={this.props.maxWidth || 450}
        modalHeight={this.props.maxHeight}
      >
        <RX.View style={[_styles.container, this.props.containerStyle]} >
          {optionalTitleText}
          <RX.View style={_styles.contentContainer}>
            {optionalContent}
          </RX.View>
          {optionalButtonsContainer}
        </RX.View>
      </Modal>
    );
  }

  private _renderButton = (buttonDef: DialogButton, index: number): JSX.Element => {
    return (
      <Button
        key={index}
        onPress={e => this._onButtonPress(e, buttonDef)}
        title={buttonDef.text}
        value={buttonDef.text || ''}
        disabled={buttonDef.disabled}
        primary={buttonDef.primary}
        warn={buttonDef.warn}
        outline={buttonDef.outline}
        secondary={buttonDef.secondary}
        buttonStyle={[_styles.button, buttonDef.buttonStyle]}
        textStyle={buttonDef.textStyle}
      />
    );
  };

  private _renderCloseText = (isHovering: boolean) => {
    return (
      <RX.Text style={[_styles.closeText, isHovering ? _styles.closeTextHover : undefined]}>
        <RX.Image source={ImageSource.close} style={_styles.closeImage} />
      </RX.Text>
    );
  }

  private _onPressClose = (e: RX.Types.SyntheticEvent) => {
    e.stopPropagation();
    this._pressCancel();
  }

  private _onButtonPress(e: RX.Types.SyntheticEvent, buttonDef: DialogButton) {
    e.stopPropagation();
    this._completeButtonPress(buttonDef);
  }

  private _completeButtonPress(buttonDef: DialogButton) {
    if (buttonDef.onPress) {
      buttonDef.onPress();
    }
  }

  private _onKeyUp = (event: RX.Types.KeyboardEvent) => {
    if (event.keyCode === KeyCodes.Escape)
      this._pressCancel();
    return false;
  }

  private _onBack = () => {
    this._pressCancel();
    return false;
  }

  private _pressCancel = () => {
    let target: DialogButton | undefined;
    _.each(this.props.buttons, button => {
      if (button.isCancel) {
        target = button;
      }
    });
    if (target) this._completeButtonPress(target);
  }

  static dismissAnimated(dialogId: string): SyncTasks.Promise<void> {
    return Modal.dismissAnimated(dialogId);
  }
}
