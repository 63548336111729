/*******************************************************************************
 * @name AppConfig
 * @author MediaFire <cavitt.glover@mediafire.com>
 * @description Provides access to configuration information for the app.
 * All of these settings are assumed to be static (set at app
 * launch time) throughout the lifetime of the app.
 ******************************************************************************/

import * as RX from 'reactxp';

class AppConfig {
  private _backend: string;
  private _backendVersion: string;
  private _root: string;
  private _frontend: string;
  private _platform: RX.Types.PlatformType;
  private _isLocal: boolean;
  private _hasTouch: boolean;
  private _startup: number;
  private _bugsnagKey: string;

  constructor() {
    this._frontend = document && document.location ? document.location.host : '';
    this._isLocal = this._frontend === 'uploader-dev.mediafire.com';
    this._root = this._isLocal ? '/' : '/uploader/';
    this._backend = this._isLocal ? 'https://www.mediafire.com' : '';
    this._backendVersion = '1.5';
    this._platform = RX.Platform.getType();
    this._hasTouch = this._platform === 'ios' || this._platform === 'android';
    this._startup = Date.now();
    this._bugsnagKey = '060a0a02457f5bd1bb6e718138f65728';
  }

  initialize() {
    // ...
  }

  isDev(): boolean {
    return __DEV__;
  }

  isLocal(): boolean {
    return this._isLocal;
  }

  getPlatformType(): RX.Types.PlatformType {
    return this._platform;
  }

  isTouchInterface(): boolean {
    return this._hasTouch;
  }

  getStartupTime(): number {
    return this._startup;
  }

  getVersion(): string {
    return __BUILD__;
  }

  getStage(): string {
    return __DEV__ ? 'development' : 'production';
  }

  getFrontendHost(): string {
    return this._frontend;
  }

  getBackendHost(): string {
    return this._backend;
  }

  getBackendVersion(): string {
    return this._backendVersion;
  }

  getProtocol(): string {
    if (this.getPlatformType() === 'web' &&
      typeof location !== 'undefined' &&
      typeof location.protocol !== 'undefined') {
      return location.protocol;
    }
    return 'https:';
  }

  getFrontendBaseUrl(): string {
    return this.getProtocol() + '//' + this._frontend;
  }

  getDocRoot(): string {
    return this._root;
  }

  getImagePath(name = ''): string {
    return this.getDocRoot() + 'images/' + name;
  }

  getBugsnagKey() {
    return this._bugsnagKey;
  }
}

export default new AppConfig();
